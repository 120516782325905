// import React, { useContext , useEffect} from 'react';

import React, {useContext, useEffect, useState} from 'react';

import {
    useParams
} from "react-router-dom";

import '../styles/aboutUs.screen.style.scss';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

/**Importation des images */

import ExpertCard from '../components/expertCard/card.component';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function Experts( props ) {
    const { page } = useParams();

    const [SERVICE, setSERVICE] = useState(null);
    const [EXPERTS, setEXPERTS] = useState(null);

    const {getSERVICE_} = useContext(ContextP);
    const {getAllExpert} = useContext(ContextP);

    
    

    const generateServices = (list) => {
        return (
            <>
                {
                    list.map((service, index)=>(
                        <>
                            <div className="homeViewContent_section9_services_list_elt">
                                <div className="homeViewContent_section9_services_list_elt_head">
                                    <h2>
                                        {service.service.name}
                                        <span style={{color: service.service.payable ? "#ff5757" : "#5dab19"}} className="homeViewContent_section9_services_list_elt_head_tag">
                                            {service.service.payable ? "payant" : "gratuit"}
                                        </span>
                                    </h2>
                                </div>
                                <div className="homeViewContent_section9_services_list_elt_body">
                                    <p dangerouslySetInnerHTML={{__html: service.service.shortDesc}}></p>
                                </div>
                                <div className="homeViewContent_section9_services_list_elt_link">
                                    <a href={'/service/' + service.service.name + "/" + service.service.id}>En savoir plus</a>
                                </div>
                            </div>
                            
                            {service.children ? generateServices(service.children) : null}
                        </>
                    ))
                }
            </>
        )
    }

    function _get_(page) {
        let data = {
            validated: true,
        }
        getAllExpert(data, 20,page,(res)=>{
            setEXPERTS(res.data);
            console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_services(page, cat=[]) {
        let data = {
            available: true,
            validated: true,
        }

        getSERVICE_(2000, page, data,(res)=>{
            // console.log(res);
            setSERVICE(res.data);
        }, (e)=>{
            console.log(e);
            return false;
        });
    }

    useEffect(() => {
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
        _get_services(1);
    }, [setEXPERTS, setSERVICE])

    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className="AboutUsContent_section_content_enc">
                        <span>Besoins d'un service?</span>
                    </div>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                Rencontrez nos experts. 
                            </h2>
                            <p>
                                
                            </p>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='##' className='button-style-3'>
                                Lire nos mentions légales
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section9_services'>
                <div className="homeViewContent_section9_services_head">
                    <h2>
                        <span>Nos services</span>
                    </h2>
                </div>

                <div className="homeViewContent_section9_services_list">
                    {/* {
                        servicesList.map((service, index) => (
                            <div className="homeViewContent_section9_services_list_elt">
                                <div className="homeViewContent_section9_services_list_elt_head">
                                    <h2>
                                        {service.name}
                                    </h2>
                                </div>
                                <div className="homeViewContent_section9_services_list_elt_body">
                                    <p>
                                        {service.description}
                                    </p>
                                </div>
                            </div>
                        ))
                    } */}
                    
                    {SERVICE !== null && generateServices(SERVICE.data)}
                </div>
            </div>

            <div className='homeViewContent_section6_expert'>
                <div className='homeViewContent_section6_expert_text'>
                    <div>
                        <p></p>
                        <a href="##" className="p_c_current">
                            Nos experts
                        </a>
                    </div>
                    <div></div>
                </div>

                <div className="homeViewContent_section4_content_list">
                    {
                        EXPERTS !== null &&
                        EXPERTS.data.map((expert_, index)=>(
                            <div className='homeViewContent_section4_content_list_elt' key={"expert_" + index}>
                                <ExpertCard price={""} elt={expert_} link={"/expert/"+expert_.person.firstName+" "+expert_.person.lastName + "/" + expert_.person.user.id} imgPath={expert_.person.image ? (PATH__.api_root + "medias/" + expert_.person.image.id + "/content") : ""}/>
                            </div>
                        ))
                    }
                </div>

                <br/>
                {
                    EXPERTS!==null &&
                    <Box
                        sx={{
                            margin: "auto",
                            width: "fit-content",
                            alignItems: "center",
                        }}
                    >
                        <Pagination count={EXPERTS.totalNbPage} page={EXPERTS.page} 
                            onChange={(e, value)=>{
                                _get_(value);
                                window.history.replaceState({}, '', '/service/experts/' + value);
                            }} variant="outlined" color="primary" />
                    </Box>
                }
            </div>
        </div>
    );
}

export default Experts;