import React, {useContext} from 'react';

import '../styles/login.screen.style.scss';

/**Imortation des helpers */

import { ContextP } from "../context/provider";

function LoginView( props ) {
    const {login} = useContext(ContextP);

    function _login_(data) {
        login(data, (res)=>{
            if(res.status === 200) {
                let _data_ = data;
                _data_["key"] = res.data;
                localStorage.setItem("erepere_user", JSON.stringify(_data_));
                window.location.href = "./";
            }
        }, (e)=>{
            console.log(e);
        })
    }

    return (
        <div className='login'>
            <div className='login_content'>
                <div className='AboutUsContent_section_back'></div>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Se connecter
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Veuillez saisir vos identifiants pour vous connecter à</span>
                        </div>
                    </div>
                </div>

                <form name="erepereLogin" action='#' method='POST' className='login_content_form'
                    onSubmit={(e) => {
                        e.preventDefault();
                        let data = {
                            email: document.forms["erepereLogin"]["email"].value,
                            password: document.forms["erepereLogin"]["password"].value,
                        };
                        _login_(data);
                    }}
                >
                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Email</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="email" required placeholder="email@gmail.com" type='email'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre email unique pour l'application</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name="password" required placeholder='***********' type='password'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre mot de passe</span>
                        </div>
                    </div>

                    <div className='login_content_form_button'>
                        <button>
                            Se connecter
                        </button>

                        <a href='/registration'>
                            Créer un compte
                        </a>
                    </div>

                    <div className='login_content_form_button returnHome'>
                        <a href='/'>
                            Retourner à l'accueil
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default LoginView;