import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

function DashboardAdminCategory( props ) {
    const { page } = useParams();


    const {getCATEGORY} = useContext(ContextP);
    const {addCATEGORY} = useContext(ContextP);
    const {updateCATEGORY} = useContext(ContextP);
    const {deleteCATEGORY} = useContext(ContextP);

    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);


    const [CATEGORY, setCATEGORY] = useState(null);
    const [actionType, setActionType] = useState("add");
    const [currentCATEGORY, setCurrentCATEGORY] = useState(null);

    function _add_() {
        addCATEGORY(currentCATEGORY, (res)=>{
            setCurrentCATEGORY(null);
            if(page) {
                _get_(page);
            } else {
                _get_(1);
            }
        }, (e)=>{
            console.log(e);
        })
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier cette catégorie ?")) {
            updateCATEGORY(currentCATEGORY.id, currentCATEGORY, (res)=>{
                setCurrentCATEGORY(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer cette catégorie ?")) {
            deleteCATEGORY(id, (res)=>{
                setCurrentCATEGORY(null);
                // setALertMessage(false);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page) {
        getCATEGORY(20,page,(res)=>{
            console.log(res);
            setCATEGORY(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [setCATEGORY])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setCurrentCATEGORY({
                                name: "",
                                description: "",                               
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>NOM</th>
                                <th>DESCRIPTION</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                CATEGORY!==null &&
                                CATEGORY.data.map((category, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{category.name}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: category.description}}></div></td>
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    setCurrentCATEGORY(category);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    _delete_(category.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    {
                        CATEGORY!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={CATEGORY.totalNbPage} page={CATEGORY.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/category/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentCATEGORY !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentCATEGORY(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentCATEGORY.name?currentCATEGORY.name:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentCATEGORY((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "name": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le nom de la catégorie</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentCATEGORY.description?currentCATEGORY.description:""} onChange={(value) => {
                                            setCurrentCATEGORY((prev)=>{
                                                return({
                                                    ...prev,
                                                    "description": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La description de la catégorie</span>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminCategory;