import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function DashboardAdminService( props ) {
    const { page } = useParams();


    const {getCATEGORY} = useContext(ContextP);
    const {getSERVICE_BY_CATEGORIES} = useContext(ContextP);
    const {addSERVICE} = useContext(ContextP);
    const {validateSERVICE} = useContext(ContextP);
    const {updateSERVICE} = useContext(ContextP);
    const {deleteSERVICE_CATEGORIES} = useContext(ContextP);
    const {deleteSERVICE} = useContext(ContextP);

    const {addMEDIA} = useContext(ContextP);
    const {deleteMEDIA} = useContext(ContextP);
    const {link_SERVICE_MEDIA} = useContext(ContextP);
    
    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);


    const [SERVICE, setSERVICE] = useState(null);
    const [CATEGORY, setCATEGORY] = useState([]);
    const [MEDIAS, setMEDIAS] = useState([]);
    const [CAT, setCAT] = useState(null);
    const [actionType, setActionType] = useState("add");
    const [currentSERVICE, setCurrentSERVICE] = useState(null);
    // const [currentSERVICECat, setCurrentSERVICECat] = useState([]);

    function _addMedias_(_medias_, ind = 0, article_id, callback=()=>{}) {
        if(ind < _medias_.length) {
            let media = new FormData();
            media.append("alt", _medias_[ind].file.type);
            media.append("description", "");
            media.append("file", _medias_[ind].file);

            addMEDIA(media, (res)=>{
                let data = {
                    label: "",
                    description: "",
                };
                link_SERVICE_MEDIA(data, article_id, res.data.id, (res)=>{
                    _addMedias_(_medias_, ind+1, article_id, callback);
                }, (e) => {
                    console.log(e);
                })
            }, (e)=>{
                console.log(e);
            })
        } else {
            callback();
        }
    }

    function _add_() {

        let cat = [];
        CATEGORY.map((category)=>{
            if(category.name !== "À la une" && category.name !== "Actualité"){
                if(document.getElementById("cat" + category.name).checked) {
                    cat.push(category.id);
                }
            }
            return true;
        });

        
        if(Array.from(document.forms["actualityForm"]["file"].files).length>0) {
            let media = new FormData();
            media.append("alt", "actualité erepere");
            media.append("description", "");
            media.append("file", document.forms["actualityForm"]["file"].files[0]);

            addMEDIA(media, (res)=>{
                // console.log(currentSERVICE);
                addSERVICE(currentSERVICE, res.data.id, cat, (res)=>{
                    if(window.confirm("voulez-vous rendre se service disponible ?")) {
                        // console.log(res);
                        validateSERVICE(res.data.id, (res)=>{
                            setCurrentSERVICE(null);
                            if(page) {
                                _get_(page, CAT);
                            } else {
                                _get_(1, CAT);
                            }
                        }, (e)=>{
                            console.log(e);
                        });
                    } else {
                        setCurrentSERVICE(null);
                        if(page) {
                            _get_(page, CAT);
                        } else {
                            _get_(1, CAT);
                        }
                    }
                }, (e)=>{
                    console.log(e);
                });
            }, (e)=>{
                console.log(e);
            })
        } else {
            addSERVICE(currentSERVICE, -1, cat, (res)=>{
                if(window.confirm("voulez-vous rendre se service disponible ?")) {
                    // console.log(res);
                    validateSERVICE(res.data.id, (res)=>{
                        setCurrentSERVICE(null);
                        if(page) {
                            _get_(page, CAT);
                        } else {
                            _get_(1, CAT);
                        }
                    }, (e)=>{
                        console.log(e);
                    });
                } else {
                    setCurrentSERVICE(null);
                    if(page) {
                        _get_(page, CAT);
                    } else {
                        _get_(1, CAT);
                    }
                }
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier ce service ?")) {
            let cat = [];
            let catDel = [];
            CATEGORY.map((category)=>{
                if(category.name !== "À la une" && category.name !== "Actualité"){
                    // console.log(document.getElementById("cat" + category.name).checked);
                    if(document.getElementById("cat" + category.name).checked) {
                        cat.push(category.id);
                    }else {
                        catDel.push(category.id);
                    }
                }
                return true;
            });

            // console.log(catDel);
            // return 0;

            // si il y a l'image de garde a modifier
            if(Array.from(document.forms["actualityForm"]["file"].files).length>0) {
                let media = new FormData();
                media.append("alt", "actualité erepere");
                media.append("description", "");
                media.append("file", document.forms["actualityForm"]["file"].files[0]);

                addMEDIA(media, (res)=>{
                    // console.log(currentSERVICE);
                    updateSERVICE(currentSERVICE.id, currentSERVICE, res.data.id, cat, (res)=>{
                        //si il a des categories a retirer
                        if(catDel.length>0) {
                            deleteSERVICE_CATEGORIES(currentSERVICE.id, catDel, (res)=>{
                                // console.log(res);
                                if(MEDIAS.length>0) {
                                    _addMedias_(MEDIAS, 0, currentSERVICE.id, ()=>{
                                        setMEDIAS([]);
                                        setCurrentSERVICE(null);
                                        if(page) {
                                            _get_(page, CAT);
                                        } else {
                                            _get_(1, CAT);
                                        }
                                    });
                                } else {
                                    setCurrentSERVICE(null);
                                    if(page) {
                                        _get_(page, CAT);
                                    } else {
                                        _get_(1, CAT);
                                    }
                                }
                                
                            }, (e)=>{
                                console.log(e);
                            });
                        } else {
                            if(MEDIAS.length>0) {
                                _addMedias_(MEDIAS, 0, currentSERVICE.id, ()=>{
                                    setMEDIAS([]);
                                    setCurrentSERVICE(null);
                                    if(page) {
                                        _get_(page, CAT);
                                    } else {
                                        _get_(1, CAT);
                                    }
                                });
                            } else {
                                setCurrentSERVICE(null);
                                if(page) {
                                    _get_(page, CAT);
                                } else {
                                    _get_(1, CAT);
                                }
                            }
                        }
                    }, (e)=>{
                        console.log(e);
                    });
                }, (e)=>{
                    console.log(e);
                });

                deleteMEDIA(currentSERVICE.image.id, (res) => {
                    
                }, (e) => {
                    console.log(e);
                });
            } else {
                updateSERVICE(currentSERVICE.id, currentSERVICE, null, cat, (res)=>{
                    if(catDel.length>0) {
                        deleteSERVICE_CATEGORIES(currentSERVICE.id, catDel, (res)=>{
                            if(MEDIAS.length>0) {
                                _addMedias_(MEDIAS, 0, currentSERVICE.id, ()=>{
                                    setMEDIAS([]);
                                    setCurrentSERVICE(null);
                                    if(page) {
                                        _get_(page, CAT);
                                    } else {
                                        _get_(1, CAT);
                                    }
                                });
                            } else {
                                setCurrentSERVICE(null);
                                if(page) {
                                    _get_(page, CAT);
                                } else {
                                    _get_(1, CAT);
                                }
                            }
                        }, (e)=>{
                            console.log(e);
                        });
                    } else {
                        if(MEDIAS.length>0) {
                            _addMedias_(MEDIAS, 0, currentSERVICE.id, ()=>{
                                setMEDIAS([]);
                                setCurrentSERVICE(null);
                                if(page) {
                                    _get_(page, CAT);
                                } else {
                                    _get_(1, CAT);
                                }
                            });
                        } else {
                            setCurrentSERVICE(null);
                            if(page) {
                                _get_(page, CAT);
                            } else {
                                _get_(1, CAT);
                            }
                        }
                    }
                }, (e)=>{
                    console.log(e);
                });
            }
        }
    }

    function _delete_(id, imageId) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer ce service ?")) {
            deleteSERVICE(id, (res)=>{
                if(imageId) {
                    deleteMEDIA(imageId, (res) => {
                        // setCurrentSERVICE(null);
                        // setALertMessage(false);
                        if(page) {
                            _get_(page, CAT);
                        } else {
                            _get_(1, CAT);
                        }
                    }, (e) => {
                        console.log(e);
                    });
                } else {
                    if(page) {
                        _get_(page, CAT);
                    } else {
                        _get_(1, CAT);
                    }
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page, cat=[]) {
        let data = {
            categories: {
                ni: {
                    categories: cat,
                },
            },
            // available: true,
        };
        getSERVICE_BY_CATEGORIES(20, page, data, (res)=>{
            // console.log(res);
            setSERVICE(res.data);
        }, (e)=>{
            console.log(e);
            return false;
        });
    }

    function _get_category(page, call=()=>{}) {
        getCATEGORY(20000,page,(res)=>{
            // console.log(res);
            call(res.data.data);
            setCATEGORY(res.data.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_category(1, (categories) => { 
            let cat = [];
            categories.map((category) => {
                if(category.name == "Actualité") {
                    cat.push(category.id);
                }
                return true;
            });
            setCAT(cat);
            // console.log(cat);
            if(page) {
                _get_(page, cat);
            } else {
                _get_(1, cat);
            }
        });
    }, [setSERVICE, setCATEGORY])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setCurrentSERVICE({
                                name: "",
                                shortDesc: "",
                                longDesc: "",
                                payable: true,
                                available: false,         
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <br/>
                <br/>
                <div>
                    {
                        SERVICE !== null ?
                            <ChronogramStyle2 chronogram={SERVICE.data} control={true} add={(parent)=>{
                                // console.log(parent);
                                setActionType("add");
                                setCurrentSERVICE({
                                    name: "",
                                    shortDesc: "",
                                    longDesc: "",
                                    payable: true,
                                    available: false,
                                    parent: parent    
                                });
                            }} delete={(service)=>{
                                _delete_(service.id, service.featuredImage ? service.featuredImage.id : null);
                            }} update={(service)=>{
                                setActionType("update");
                                setCurrentSERVICE(service);
                            }} validate={(service)=>{
                                if(window.confirm("voulez-vous rendre se service disponible ?")) {
                                    validateSERVICE(service.id, (res)=>{
                                        if(page) {
                                            _get_(page, CAT);
                                        } else {
                                            _get_(1, CAT);
                                        }
                                    }, (e)=>{
                                        console.log(e);
                                    });
                                }
                            }}/>
                        :
                            null
                    }
                    {/* <table className='table-classic'> */}
                        {/* <thead>
                            <tr>
                                <th>TITRE</th>
                                <th>SOMMAIRE</th>
                                <th>STATUS</th>
                                <th>Action</th>
                            </tr>
                        </thead> */}
                        {/* <tbody> */}
                            {
                                // SERVICE!==null &&
                                // SERVICE.data.map((service, index)=>(
                                //     <tr style={{
                                //         background: index%2===0 ? "#00000005" : "transparent",
                                //     }}>
                                //         <td><strong>{service.title}</strong></td>
                                //         <td><div className='content' dangerouslySetInnerHTML={{__html: service.summary}}></div></td>
                                //         <td><strong>{service.status}</strong></td>
                                //         <td>
                                //             <button className='update'
                                //                 onClick={(e)=>{
                                //                     setActionType("update");
                                //                     setCurrentSERVICE(service);
                                //                 }}
                                //             >
                                //                 Modifier
                                //             </button>
                                //             <button className='delete'
                                //                 onClick={(e)=>{
                                //                     // setCurrentSERVICE(service);
                                //                     _delete_(service.id, service.image.id);
                                //                 }}
                                //             >
                                //                 supprimer
                                //             </button>
                                //         </td>
                                //     </tr>
                                // ))
                            }
                        {/* </tbody> */}
                    {/* </table> */}
                    <br/>
                    <br/>

                    {
                        SERVICE!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={SERVICE.totalPage} page={SERVICE.page} 
                                onChange={(e, value)=>{
                                    _get_(value, CAT);
                                    window.history.replaceState({}, '', '/dashboard/admin/service/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentSERVICE !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentSERVICE(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="actualityForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>IMAGE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input name='file' type='file' accept='image/*'/>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>L'image représentative du service (facultatif)</span>
                                    </div>
                                    {
                                        currentSERVICE.featuredImage ?
                                            <div className='form_input_image'>
                                                <img src={PATH__.api_root + "medias/" + currentSERVICE.featuredImage.id + "/content" } alt={currentSERVICE.title}/>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentSERVICE.name?currentSERVICE.name:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentSERVICE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "name": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le nom du service</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>COURTE DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentSERVICE.shortDesc?currentSERVICE.shortDesc:""} onChange={(value) => {
                                            setCurrentSERVICE((prev)=>{
                                                return({
                                                    ...prev,
                                                    "shortDesc": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Une courte description du service</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentSERVICE.longDesc?currentSERVICE.longDesc:""} onChange={(value) => {
                                            setCurrentSERVICE((prev)=>{
                                                return({
                                                    ...prev,
                                                    "longDesc": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La description du service</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>PAYANT</span>
                                    </div>
                                    <br/>
                                    <div className='form_input_box'>
                                        <input checked={currentSERVICE.payable} id="payable" name='payable' type='checkbox'
                                            onChange={(e)=>{
                                                // console.log(e.target.checked);
                                                setCurrentSERVICE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "payable": e.target.checked
                                                    });
                                                })
                                            }}
                                        />
                                        <label style={{marginLeft: "5px"}} htmlFor={"payable"}>C'est payant</label>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>CATÉGORIES</span>
                                    </div>
                                    <br/>
                                    {
                                        CATEGORY.map((cat, index) => (
                                            cat.name !== "À la une" && cat.name !== "Actualité" ?
                                                (
                                                    actionType==="add" ?
                                                    <div className='form_input_box'>
                                                        <input id={"cat" + cat.name} name='category' type='checkbox'/>
                                                        <label style={{marginLeft: "5px"}} htmlFor={"cat" + cat.name}>{cat.name}</label>
                                                    </div>
                                                :
                                                    currentSERVICE.categories &&
                                                    <div className='form_input_box'>
                                                        <input defaultChecked={currentSERVICE.categories.some(item => item.category.name === cat.name)} id={"cat" + cat.name} name='category' type='checkbox'/>
                                                        <label style={{marginLeft: "5px"}} htmlFor={"cat" + cat.name}>{cat.name}</label>
                                                    </div>
                                                )
                                            :
                                                null
                                        ))
                                    }
                                </div>

                                <div className='form_button'>
                                    <button>
                                        VALIDER
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminService;