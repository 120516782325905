import React, {useState, useContext, useEffect} from 'react';
// import {
//     useParams
// } from "react-router-dom";

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// import Box from "@mui/material/Box";
// import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

// import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';

// import PATH_ from '../GLOBAL-VAR/globals-urls';
// const PATH__ = new PATH_();

function DashboardExpertService( props ) {
    // const { page } = useParams();

    const {getSERVICE} = useContext(ContextP);
    const {getExpertServicesAndRates} = useContext(ContextP);
    const {submit_SERVICE_RATE} = useContext(ContextP);
    const {update_SERVICE_RATE} = useContext(ContextP);
    const {submit_SERVICE_FREE} = useContext(ContextP);
    const {detachExpertService} = useContext(ContextP);


    const [SERVICE, setSERVICE] = useState(null);
    const [user, setUser] = useState(null);
    const [ALL_SERVICE, setALL_SERVICE] = useState(null);
    const [addService, setAddService] = useState(false);
    const [currentSERVICE, setCurrentSERVICE] = useState(null);
    // const [currentSERVICECat, setCurrentSERVICECat] = useState([]);


    function _get_(id) {
        getExpertServicesAndRates(2000, 1, id, (res)=>{
            console.log(res);
            setSERVICE(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_all_SERVICE() {
        getSERVICE(2000, 1, (res)=>{
            // console.log(res);
            setALL_SERVICE(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _addService_(service) {
        if(service.service.payable) {
            let price = window.prompt("Il sagit d'un service payant, veuillez definir votre tarif:");
            let data = {
                price: Number(price),
                recipientPhone: "00000000",
            };
            if(price !== "" && Number(price) > 0) {
                submit_SERVICE_RATE(service.service.id, data, (res)=>{
                    // console.log(res);
                    setAddService(false);
                    _get_(user.key.user.id);
                }, (e)=>{
                    return false;
                });
            } 
        } else {
            submit_SERVICE_FREE(service.service.id, (res)=>{
                setAddService(false);
                _get_(user.key.user.id);
            }, (e)=>{
                return false;
            });
        }
    }

    function _updateServiceRate_(service) {
        let price = window.prompt("veuillez definir votre nouveau tarif:");
        let data = {
            price: Number(price),
        };
        if(price !== "" && Number(price) > 0) {
            update_SERVICE_RATE(service.service.id, data, (res)=>{
                _get_(user.key.user.id);
            }, (e)=>{
                return false;
            });
        }
    }

    function _detachService_(service) {
        detachExpertService(service.service.id, (res)=>{
            _get_(user.key.user.id);
        }, (e)=>{
            return false;
        });
    }

    const generateServices = (list) => {
        return (
            <>
                <tbody style={{paddingLeft: "10px"}}>
                    {
                        list.map((service, index)=>(
                            <>
                                <tr>
                                    <td htmlFor={service.service.name + "-" + index} className='serviceList_elt_name'>{service.service.name}<button className='add'
                                        onClick={(e) => {
                                            _addService_(service);
                                        }}
                                    >ajouter</button></td>
                                    {/* {
                                        service.service.payable && SERVICE_selected.includes(service.service.id) ? <input id={service.service.id + "-" + index + "-tarif"} style={{marginLeft: "10px"}} type='number' placeholder='tarif'/> : null
                                    } */}
                                    {service.children ? generateServices(service.children) : null}
                                </tr>
                                
                            </>
                        ))
                    }
                </tbody>
                
            </>
        )
    }

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("erepere_user"));
        setUser(currentUser);
        _get_(currentUser.key.user.id);
        _get_all_SERVICE();
    }, [setSERVICE])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setAddService(true);
                            return true;
                        }}
                    >AJOUTER UN SERVICE À MA LISTE</button>
                </div>
                <br/>
                <br/>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>NOM</th>
                                <th>DESCRIPTION</th>
                                <th>TARIF</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                SERVICE!==null &&
                                SERVICE.data.map((service_, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{service_.service.name}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: service_.service.shortDesc}}></div></td>
                                        <td>{
                                            service_.service.payable? 
                                                <div>
                                                    <div style={{display: "flex"}}>
                                                        <span style={{marginRight: "3px", fontWeight: "bold"}}>{service_.rate.price} </span> <span> XOF</span>
                                                    </div>
                                                    <button className='confirm'
                                                        onClick={(e)=>{
                                                            _updateServiceRate_(service_);
                                                            return true
                                                        }}
                                                    >
                                                        modifier
                                                    </button>
                                                </div> : "gratuit"}
                                        </td>
                                        <td>
                                            <div style={{display: "flex"}}>
                                                <button className='update'
                                                    onClick={(e)=>{
                                                        return true
                                                    }}
                                                >
                                                    VOIR
                                                </button>
                                                <button className='delete'
                                                    onClick={(e)=>{
                                                        if(window.confirm("Voulez-vous vraiment retirer se service de la liste ?")) {
                                                            _detachService_(service_);
                                                        }
                                                        return true;
                                                    }}
                                                >
                                                    retirer
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    
                </div>
                    {
                        addService ?
                            <section className='information'>
                                <div className='information_content'>
                                    <div className='information_content_close'>
                                        <button onClick={(e) => {
                                            setAddService(false);
                                        }}>
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="information_content_head">
                                        <div className="information_content_head_id">
                                            Liste des services disponibles
                                        </div>
                                    </div>

                                    {
                                        ALL_SERVICE !== null ?
                                            <table className='table-classic'>
                                                {generateServices(ALL_SERVICE.data)}
                                            </table>
                                        :
                                            null
                                    }
                                </div>
                            </section>
                        :
                            null
                    }
                </div>
        </div>
    );
}

export default DashboardExpertService;