// import React, { useContext , useEffect} from 'react';

import React, {useContext, useEffect, useState} from 'react';

// import { ContextP } from "../context/provider";

import '../styles/homeView.screen.style.scss';

/**Importation des images */
// import back2 from '../assets/images/istockphoto-1486568419-612x612.jpg';
import back3 from '../assets/images/back3.png';
import back4 from '../assets/images/back4.png';
import usrIcon from '../assets/icons/user-1633250_1280.png';

import Card from '../components/card/card.component';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function HomeView( props ) {
    const {getCATEGORY} = useContext(ContextP);
    const {getARTICLE_BY_CATEGORIES} = useContext(ContextP);
    const {getAllExpert} = useContext(ContextP);

    const [EXPERTS, setEXPERTS] = useState(null);
    const [UNE, setUNE] = useState(null);
    const [recentACTUALITY, setRecentACTUALITY] = useState([]);

    function _get_experts(page) {
        let data = {
            validated: true,
        }
        getAllExpert(data, 20,page,(res)=>{
            setEXPERTS(res.data);
            // console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_by(limit=10, page=1, categories, call=()=>{}) {
        getARTICLE_BY_CATEGORIES(limit, page, categories,(res)=>{
            // console.log(res.data);
            call(res.data.data);
        }, (e)=>{
            console.log(e);
            return false;
        });
    }

    useEffect(() => {
        getCATEGORY(20000,1,(res)=>{
            let cat = [];
            res.data.data.map((category) => {
                if(category.name === "Actualité") {
                    cat.push(category.id);
                }
                return true;
            });
            let data = {
                categories: {
                    in: {
                        all: true,
                        categories: cat,
                    },
                },
                topNews: true,
                state: "Published",
            };
            let data_tha = {
                categories: {
                    in: {
                        all: true,
                        categories: cat,
                    },
                },
                state: "Published",
            };
            _get_by(1, 1, data, (res)=>{
                setUNE(res[0]);
                // console.log(res);
            });
            _get_by(3, 1, data_tha, (res)=>{
                setRecentACTUALITY(res);
            });
        }, (e)=>{
            return false;
        });
        _get_experts(1);
    }, []);

    
    return (
        <div className='homeViewContent'>
            <div className='homeViewContent_section1'>
                <div className='AboutUsContent_section_back'></div>
                <div className='homeViewContent_section1_content'>
                    <div className="homeViewContent_section1_content_enc">
                        <span>À la une</span>
                    </div>
                    <div className='homeViewContent_section1_content_new'>
                        <div>
                            <h2>
                                {
                                    UNE !== null ?
                                        UNE.title
                                    : null
                                }
                            </h2>
                        </div>
                        <div className='homeViewContent_section1_content_new_info'>
                            <div>
                                {
                                    UNE !== null ?
                                        <div dangerouslySetInnerHTML={{__html: UNE.summary}}></div>
                                    : null
                                }
                                {
                                    UNE !== null ?
                                        <a className='button-style-1' href={"/actuality/"+UNE.title+"/"+UNE.id} >En savoir plus</a>
                                    : null
                                }
                            </div>
                            <div>
                                {
                                    UNE !== null ?
                                        <img src={PATH__.api_root + "medias/" + UNE.image.id + "/content"} alt='erepere'/>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section2'>
                <div className="homeViewContent_section2_">
                    <div className='homeViewContent_section2_content'>
                        <br/>
                        <h2>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-nested" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"/>
                            </svg> Votre Guide Virtuel pour Investir au Bénin
                        </h2>
                        <p className='textForm'>
                            Chez erepere.fr, nous sommes déterminés à simplifier et à faciliter votre parcours d'investissement au Bénin. Que vous soyez une entreprise cherchant à s'implanter dans ce marché dynamique ou un particulier désireux de diversifier ses investissements, nous sommes là pour vous accompagner à chaque étape du processus.
                        </p>
                    </div>

                    <div className='homeViewContent_section2_content'>
                        <h2 className='h-green'>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-nested" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"/>
                            </svg> Des Informations Fiables et Actuelles
                        </h2>
                        <p className='textForm'>
                            Nous fournissons des informations détaillées sur la fiscalité, les impôts et les procédures douanières au Bénin, vous permettant ainsi de prendre des décisions éclairées pour votre entreprise ou vos investissements personnels.
                        </p>
                    </div>

                    <div className='homeViewContent_section2_content'>
                        <h2>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-nested" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"/>
                            </svg> Des Conseils Personnalisés d'Experts
                        </h2>
                        <p className='textForm'>
                            Notre équipe d'experts-conseils est là pour répondre à vos questions spécifiques et vous fournir des conseils sur mesure adaptés à vos besoins. Que vous recherchiez des conseils en matière de gestion d'entreprise, des analyses de marché ou des recommandations d'investissement, nous sommes là pour vous aider.
                        </p>
                    </div>

                    <div className='homeViewContent_section2_content'>
                        <h2 className='h-green'>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-nested" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"/>
                            </svg> Une Approche Personnalisée
                        </h2>
                        <p className='textForm'>
                            Chez erepere.fr, nous comprenons que chaque situation est unique. C'est pourquoi nous vous offrons une approche personnalisée, en prenant en compte vos objectifs, vos défis et vos contraintes spécifiques pour vous proposer des solutions adaptées.
                        </p>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section3'>
                <div className='homeViewContent_section3_content'>
                    <div className="homeViewContent_section3_content_enc">
                        <span>Sujets tendances</span>
                    </div>

                    <div className="homeViewContent_section3_content_list">
                        <div className='homeViewContent_section3_content_list_elt'>
                            <a href='##'>
                                <h2>
                                    <svg 
                                            width="1em" height="1em" viewBox="0 0 27 32" enable-background="new 0 0 27 32">
                                        <g>
                                            <path fill="currentColor" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
                                                C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
                                                c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
                                                c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
                                                c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
                                                c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
                                                C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
                                                c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
                                                c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
                                                c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
                                                c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
                                                c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
                                                c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
                                                c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
                                        </g>
                                    </svg> La révolution de l’impôt forfaitaire d’État : où en sont les choses aujourd’hui
                                </h2>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </a>
                        </div>
                        
                        <div className='homeViewContent_section3_content_list_elt'>
                            <a href='##'>
                                <h2>
                                    <svg 
                                            width="1em" height="1em" viewBox="0 0 27 32" enable-background="new 0 0 27 32">
                                        <g>
                                            <path fill="currentColor" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
                                                C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
                                                c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
                                                c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
                                                c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
                                                c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
                                                C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
                                                c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
                                                c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
                                                c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
                                                c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
                                                c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
                                                c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
                                                c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
                                        </g>
                                    </svg> Corporis totam, ad nemo porro, doloribus sint veniam ipsam dolores aspernatur atque accusamus vitae debitis perferendis animi aliquam necessitatibus!
                                </h2>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </a>
                        </div>

                        <div className='homeViewContent_section3_content_list_elt'>
                            <a href='##'>
                                <h2>
                                    <svg 
                                            width="1em" height="1em" viewBox="0 0 27 32" enable-background="new 0 0 27 32">
                                        <g>
                                            <path fill="currentColor" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
                                                C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
                                                c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
                                                c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
                                                c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
                                                c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
                                                C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
                                                c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
                                                c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
                                                c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
                                                c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
                                                c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
                                                c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
                                                c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
                                        </g>
                                    </svg> Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                </h2>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </a>
                        </div>

                        <div className='homeViewContent_section3_content_list_elt'>
                            <a href='##'>
                                <h2>
                                    <svg 
                                            width="1em" height="1em" viewBox="0 0 27 32" enable-background="new 0 0 27 32">
                                        <g>
                                            <path fill="currentColor" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
                                                C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
                                                c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
                                                c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
                                                c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
                                                c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
                                                C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
                                                c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
                                                c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
                                                c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
                                                c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
                                                c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
                                                c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
                                                c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
                                        </g>
                                    </svg> Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                </h2>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </a>
                        </div>

                        <div className='homeViewContent_section3_content_list_elt'>
                            <a href='##'>
                                <h2>
                                    <svg 
                                            width="1em" height="1em" viewBox="0 0 27 32" enable-background="new 0 0 27 32">
                                        <g>
                                            <path fill="currentColor" d="M21.06-0.024c-2.2,0-4.06,2.539-4.06,5.543V6h-0.775c-0.378,0-0.733,0.137-1.003,0.386L0.479,20.06
                                                C0.172,20.345-0.003,20.749,0,21.169c0.002,0.42,0.182,0.822,0.491,1.104l10.262,9.319c0.272,0.248,0.624,0.383,0.99,0.384
                                                c0.388,0,0.754-0.15,1.032-0.422l13.773-13.479C26.836,17.794,27,17.402,27,16.999V7.479C27,6.664,26.335,6,25.518,6h-0.416
                                                c0.011-0.165,0.018-0.332,0.018-0.501C25.12,2.468,23.299-0.024,21.06-0.024z M18,5.519c0-2.42,1.43-4.542,3.06-4.542
                                                c1.659,0,3.061,2.06,3.061,4.498c0,0.18-0.021,0.351-0.035,0.525H18V5.519z M25.518,7C25.788,7,26,7.21,26,7.479v9.519
                                                c0,0.136-0.055,0.268-0.15,0.361L12.076,30.838c-0.173,0.171-0.469,0.178-0.651,0.013L1.163,21.533
                                                C1.059,21.438,1.001,21.307,1,21.164c-0.001-0.143,0.056-0.275,0.159-0.371L15.901,7.12C16.008,7.021,16.136,7,16.225,7h7.706
                                                c-0.208,0.859-0.58,1.597-1.067,2.118c-0.013-0.013-0.021-0.028-0.034-0.04c-0.984-0.946-2.69-0.947-3.675,0
                                                c-0.497,0.478-0.771,1.115-0.771,1.793s0.273,1.316,0.771,1.794c0.492,0.473,1.145,0.734,1.837,0.734
                                                c0.693,0,1.346-0.261,1.838-0.734c0.497-0.479,0.771-1.116,0.771-1.794c0-0.306-0.063-0.602-0.17-0.88
                                                c0.733-0.718,1.283-1.77,1.535-2.992H25.518z M22.6,10.872c0,0.404-0.164,0.786-0.464,1.074c-0.608,0.586-1.678,0.587-2.288,0
                                                c-0.3-0.288-0.464-0.669-0.464-1.074c0-0.186,0.039-0.367,0.106-0.536C20.139,10.96,21.06,11,21.146,11c0.002,0,0.004,0,0.005,0
                                                c0.502,0,0.981-0.136,1.424-0.374C22.588,10.707,22.6,10.788,22.6,10.872z M20.16,9.57c0.249-0.144,0.532-0.226,0.831-0.226
                                                c0.391,0,0.757,0.138,1.049,0.379c-0.283,0.135-0.578,0.228-0.891,0.228C21.118,9.951,20.526,9.94,20.16,9.57z"/>
                                        </g>
                                    </svg> Et voluptate, error non nisi porro quae ea tempora mollitia doloremque sed tenetur! Corrupti sequi eligendi dolor architecto vel atque aliquid repudiandae.
                                </h2>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div className='homeViewContent_section4'>
                <div className='homeViewContent_section4_content'>
                    <div className="homeViewContent_section4_content_enc">
                        <span>Actualités</span>
                    </div>

                    <div className="homeViewContent_section4_content_list">
                        {
                            recentACTUALITY.map((news, index)=>(
                                <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                    <Card elt={news} link={"/actuality/"+news.title+"/"+news.id} imgPath={PATH__.api_root + "medias/" + news.image.id + "/content"}/>
                                </div>
                            ))
                        }
                    </div>

                    <div className="homeViewContent_section4_content_more">
                        <button>
                            <span>afficher plus </span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-double-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section5'>
                <div className="homeViewContent_section5_content">
                    <div className='homeViewContent_section5_content_img'>
                        <img src={back3} alt='erepere'/>
                    </div>
                    <div className='homeViewContent_section5_content_text'>
                        <h2>
                            eRepere
                        </h2>
                        <p>
                            Votre Guide Numérique pour l'Investissement au Bénin
                            erepere.fr est votre portail dédié à la facilitation de l'investissement au Bénin, que vous soyez une entreprise cherchant à s'implanter dans ce marché dynamique ou un particulier désireux d'explorer les opportunités d'investissement.
                        </p>
                        <h3>Pourquoi erepere.fr ?</h3>
                        <p>
                            Nous comprenons les défis et les complexités auxquels sont confrontés les investisseurs potentiels lorsqu'ils envisagent de s'aventurer dans un nouveau marché. C'est pourquoi erepere.fr a été créé : pour simplifier ce processus et vous fournir les outils, les informations et les conseils dont vous avez besoin pour réussir.
                        </p>

                        <div className='homeViewContent_section5_content_link'>
                            <a href='/about-us' className='button-style-1'>
                                EN SAVOIR PLUS SUR NOUS...
                            </a>
                        </div>
                    </div>
                </div>

                <div className='homeViewContent_section5_expert'>
                    <div className='homeViewContent_section5_expert_back'>
                        <img src={back4} alt=''/>
                    </div>
                    
                    <div className='homeViewContent_section5_expert_text'>
                        <p></p>
                        <h2>
                            Nous avons les
                            experts
                            dont vous avez besoin
                        </h2>
                    </div>

                    <div className='homeViewContent_section5_expert_list'>
                        {
                            EXPERTS &&
                            EXPERTS.data.map((exp, index)=>(
                                <div className='homeViewContent_section5_expert_list_exp'>
                                    <div>
                                        {
                                            exp.img ?
                                                <img src={exp.img} alt='expert erepere'/>
                                            :   <img src={usrIcon} alt='expert erepere'/>
                                        }
                                    </div>
                                    <div className='homeViewContent_section5_expert_list_exp_text'>
                                        <div className='homeViewContent_section5_expert_list_exp_text_title'>
                                            Expert
                                        </div>
                                        <a href={"/expert/"+exp.person.firstName+" "+exp.person.lastName + "/" + exp.person.user.id}>
                                            <h3>
                                                {exp.person.firstName + " " + exp.person.lastName}
                                            </h3>
                                        </a>
                                        <span>
                                            {exp.workStation + " à " + exp.workPlace}
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='homeViewContent_section5_expert_button'>
                        <a href='/experts' className='button-style-2'>
                            VOIR TOUS LES EXPERTS ET DEMMANDER UN RENDEZ-VOUS
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeView;