import React, {useState, useContext, useEffect} from 'react';
// import { Link } from 'react-router-dom';

import {
    useParams
} from "react-router-dom";

import '../styles/search.screen.style.scss'; 

import { ContextP } from "../context/provider";

export default function SearchView(){
    const {search} = useContext(ContextP);
    const [SEARCH_VALUE, setSEARCH_VALUE] = useState("");

    const { value } = useParams();
    const { page } = useParams();

    function _search_(value, limit=20, page=1, call=()=>{}) {
        search(value, limit, page,(res)=>{
            console.log(res);
        }, (e)=>{
            console.log(e);
            return false;
        });
    }

    useEffect(() => {
        if(value) {
            setSEARCH_VALUE(value);
        }
        _search_(value, 20, (page ? page : 1));
    },[]);

    return (
        <div className='homeViewContent faqs'>
            <div className='homeViewContent_section faqsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='searchView'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <div className='searchView_form_content'>
                            <input type='search' value={SEARCH_VALUE}
                                onChange={(e) => {
                                    setSEARCH_VALUE(e.target.value);
                                }}
                            />
                            <button>RECHERCHER</button>
                        </div>
                    </form>

                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}