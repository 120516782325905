// import React, { useContext , useEffect} from 'react';

import React, {useContext, useEffect, useState} from 'react';
import {
    useParams
} from "react-router-dom";

import '../styles/aboutUs.screen.style.scss';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

/**Importation des images */

import ExpertCard from '../components/expertCard/card.component';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function ServiceScreen( props ) {
    const { name } = useParams();
    const { id } = useParams();
    const { page } = useParams();
    
    const [SERVICE, setSERVICE] = useState(null);
    const [EXPERTS, setEXPERTS] = useState(null);
    const [SERVICE_NAME, setSERVICE_NAME] = useState(null);
    const [SERVICE_ID, setSERVICE_ID] = useState(null);

    const {getONE_SERVICE} = useContext(ContextP);
    const {GET_SERVICE_EXPERT_FREE} = useContext(ContextP);
    const {GET_SERVICE_EXPERT_RATE} = useContext(ContextP);
    
    
    function _get_(id, page=1) {
        getONE_SERVICE(id, (res)=>{
            // console.log(res);
            setSERVICE(res.data);
            if(res.data.service.payable) {
                _get_for_rate(id, page);
            } else {
                _get_for_free(id, page);
            }
        }, (e)=>{
            return false;
        });
    }

    function _get_for_rate(id, page) {
        GET_SERVICE_EXPERT_RATE(id, 20, page, (res)=>{
            console.log(res);
            setEXPERTS(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_for_free(id, page) {
        GET_SERVICE_EXPERT_FREE(id, 20, page, (res)=>{
            console.log(res);
            setEXPERTS(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        if(name) {
            setSERVICE_NAME(name);
        }
        if(id) {
            setSERVICE_ID(id);
        }
        if(page) {
            _get_(id, page);
        } else {
            _get_(id, 1);
        }
        // _get_(id);
    }, [setSERVICE])

    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className="AboutUsContent_section_content_enc">
                        <span>SERVICE</span>
                    </div>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                {SERVICE_NAME !== null ? SERVICE_NAME : ""}
                            </h2>
                            
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='##' className='button-style-3'>
                                Lire nos mentions légales
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section9_services'
                style={{maxWidth: "1200px"}}
            >
                <div className="homeViewContent_section9_services_head">
                    <h2>
                        <span>À propos</span>
                    </h2>
                </div>

                <div className='homeViewContent_section9_services_desc'>
                    <p dangerouslySetInnerHTML={{__html: SERVICE !== null ? SERVICE.service.shortDesc : ""}}></p>
                </div>

                <div className='homeViewContent_section9_services_desc'>
                    <p dangerouslySetInnerHTML={{__html: SERVICE !== null ? SERVICE.service.longDesc : ""}}></p>
                </div>

                <div className="homeViewContent_section9_services_list">
                   
                </div>
            </div>

            <div className='homeViewContent_section6_expert'>
                <br/>
                <br/>
                <div className="AboutUsContent_section_content_enc">
                    <span>Les experts</span>
                </div>

                <div className="homeViewContent_section4_content_list">
                    {
                        EXPERTS !== null &&
                        EXPERTS.data.map((expert_, index)=>(
                            <div className='homeViewContent_section4_content_list_elt' key={"expert_" + index}>
                                <ExpertCard price={expert_.price} elt={expert_.definer} link={"/expert/"+expert_.definer.person.firstName+" "+expert_.definer.person.lastName + "/" + expert_.definer.person.user.id} imgPath={expert_.definer.person.image ? (PATH__.api_root + "medias/" + expert_.definer.person.image.id + "/content") : ""}/>
                            </div>
                        ))
                    }
                </div>

                <br/>
                {
                    EXPERTS!==null &&
                    <Box
                        sx={{
                            margin: "auto",
                            width: "fit-content",
                            alignItems: "center",
                        }}
                    >
                        <Pagination count={EXPERTS.totalNbPage} page={EXPERTS.page} 
                            onChange={(e, value)=>{
                                _get_(SERVICE_ID, value);
                                window.history.replaceState({}, '', '/service/expert/' + SERVICE_NAME + "/" + SERVICE_ID + "/" + value);
                            }} variant="outlined" color="primary" />
                    </Box>
                }

                {/* <div className='homeViewContent_section5_expert_button'>
                    <a href='##' className='button-style-2'>
                        VOIR TOUS LES EXPERTS ET DEMMANDER UN RENDEZ-VOUS
                    </a>
                </div> */}
            </div>
        </div>
    );
}

export default ServiceScreen;