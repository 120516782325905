import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

class USERRequest {
    registration(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "register/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    login(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "auth/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    sendExpertRequest(data, resume) {
        // console.log(data);
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "register/expert?resume=" + resume,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getUserInfo(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "users/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    updateUserInfos(data) {
        // console.log(data);
        return (
            axios({
                method: 'patch',
                data: data,
                url: PATH__.api_root + "users",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }
}

export default USERRequest;