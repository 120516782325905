import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

class CategoryRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "categories/",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    GetAll(limit = 20, page = 1) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "categories/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data) {
        return (
            axios({
                method: 'patch',
                data: data,
                url: PATH__.api_root + "categories/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "categories/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }
}

export default CategoryRequest;