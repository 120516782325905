// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';

/**Importation des composants */
// import PointerComponent from '../pointer.component/pointer.component';


/**Importation des images*/

import PATH_ from '../../GLOBAL-VAR/globals-urls';


import VARIABLES from '../../styles/model-7-1/utils/_variables.scss';

const PATH__ = new PATH_();

class ChronogramStyle2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    _generateChronogram(list, state=0, parent=null) {
        return(
            <ul className={ state===0 ? 'chronogram2_list_' : "chronogram2_list"}>
                {
                    list.map((elt, index) => (
                        <li className={state===0 ? 'chronogram2_list_elt chronogram2_list_' : "chronogram2_list_elt"}>
                            <div className='chronogram2_list_elt_text'>
                                {
                                    elt.service.featuredImage ?
                                        <div className='chronogram2_list_elt_text_image'>
                                            <img src={PATH__.api_root + "medias/" + elt.service.featuredImage.id + "/content" } alt={elt.service.title}/>
                                        </div>
                                    :
                                        null
                                }
                                <span className='chronogram2_list_elt_text_'>
                                    <div className={this.props.control ? "chronogram2_list_elt_text_ctn_" : "chronogram2_list_elt_text_ctn"} style={{
                                        fontWeight: "normal",
                                        letterSpacing: "0px",
                                        fontSize: "1.1em",
                                        color: elt.service.available ? VARIABLES.color_3 : ( VARIABLES.color_4)
                                    }}>
                                        <span style={{
                                            borderColor: elt.service.available ? VARIABLES.color_3 : ( VARIABLES.color_4)
                                        }} className='chronogram2_list_elt_text_ctn_name'>{elt.service.name}</span>
                                        {
                                            this.props.control &&
                                                <div className='chronogram2_list_elt_text_ctn_control'>
                                                    {
                                                        !elt.service.available &&
                                                            <button className='validate'
                                                                onClick={(e) => {
                                                                    this.props.validate(elt.service);
                                                                    return true;
                                                                }}
                                                            >
                                                                valider.
                                                            </button>
                                                    }
                                                    <button className='edit'
                                                        onClick={(e) => {
                                                            this.props.update(elt.service);
                                                            return true;
                                                        }}
                                                    >
                                                        modif.
                                                    </button>
                                                    <button className='delete'
                                                        onClick={(e) => {
                                                            // console.log(elt);
                                                            this.props.delete(elt.service);
                                                            return true;
                                                        }}
                                                    >
                                                        supp.
                                                    </button>
                                                    <button className='add'
                                                        onClick={(e) => {
                                                            // console.log(elt.service.id);
                                                            this.props.add(elt.service.id);
                                                            return true;
                                                        }}
                                                    >
                                                        ajouter sous service
                                                    </button>
                                                </div>
                                        }
                                    </div>

                                    {/* <div className='chronogram2_list_elt_text_info'>
                                        {
                                            elt.service.categories.map((category, index_)=>(
                                                <div className='chronogram2_list_elt_text_info_elt' key={"info" + index + "_" + index_}>
                                                    - <span>{category.category.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                </span>
                                {state!==0? <div className='chronogram2_list_elt_text_line'
                                    style={{
                                        background: elt.service.available ? VARIABLES.color_3 : (VARIABLES.color_4)
                                    }}
                                ></div> : null}
                                {state!==0? <div className='chronogram2_list_elt_text_line_hr'
                                    style={{
                                        background: elt.service.available ? VARIABLES.color_3 : (VARIABLES.color_4)
                                    }}
                                ></div> : null}
                            </div>
                            {elt.children ? this._generateChronogram(elt.children, state+1, elt.service.id) : ""}
                        </li>
                    ))
                }
            </ul>
        )
    }

    
    render() {
        return (
            <div>
                <div className='chronogram2'>
                    {this._generateChronogram(this.props.chronogram)}
                </div>
            </div>
        )
    }
}

export default ChronogramStyle2;