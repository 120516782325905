// import React, { useContext , useEffect} from 'react';

import React from 'react';

// import { ContextP } from "../context/provider";

import '../styles/aboutUs.screen.style.scss';

/**Importation des images */

function AboutUs( props ) {
    
    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className="AboutUsContent_section_content_enc">
                        <span>Nous sommes</span>
                    </div>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                Un repère dématérialisé pour les entreprises et les particuliers qui veulent investir au Bénin. 
                            </h2>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='##' className='button-style-3'>
                                NOUS CONTACTER
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section2'>
                <div className='AboutUsContent_section2_back'></div>
                <div className='AboutUsContent_section2_content'>
                    <div className="AboutUsContent_section2_content_enc">
                        <span>Pourquoi erepere.fr ?</span>
                    </div>
                    <div className='AboutUsContent_section2_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section2_content_text_p'>
                            <p>
                                Nous comprenons les défis et les complexités auxquels sont confrontés les investisseurs potentiels lorsqu'ils envisagent de s'aventurer dans un nouveau marché. C'est pourquoi erepere.fr a été créé : pour simplifier ce processus et vous fournir les outils, les informations et les conseils dont vous avez besoin pour réussir.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section3'>
                <div className='AboutUsContent_section3_back'></div>
                <div className='AboutUsContent_section3_content'>
                    <div className="AboutUsContent_section3_content_enc">
                        <span>Ce que nous offrons</span>
                    </div>
                    <div className='AboutUsContent_section3_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section3_content_text_p'>
                            <p>
                                Sur erepere.fr, vous trouverez une mine d'informations précieuses sur la fiscalité, les réglementations, les procédures douanières et les opportunités d'investissement au Bénin. Notre équipe d'experts-conseils est là pour répondre à vos questions spécifiques, vous guider à travers les défis potentiels et vous aider à prendre des décisions éclairées.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section2'>
                <div className='AboutUsContent_section2_back'></div>
                <div className='AboutUsContent_section2_content'>
                    <div className="AboutUsContent_section2_content_enc">
                        <span>Notre Engagement envers Vous</span>
                    </div>
                    <div className='AboutUsContent_section2_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section2_content_text_p'>
                            <p>
                                Notre engagement envers vous est simple : fournir des informations fiables, des conseils pertinents et un soutien personnalisé tout au long de votre parcours d'investissement au Bénin. Nous croyons fermement en la réussite de nos clients, et nous sommes déterminés à vous aider à atteindre vos objectifs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;