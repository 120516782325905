import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

// const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

class SEARCHRequest {
    search(searchValue, limit = 20, page = 1) {
        return (
            axios({
                method: 'post',
                data: {},
                url: PATH__.api_root + "search?query=" + searchValue + "&limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default SEARCHRequest;