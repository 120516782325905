import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function DashboardAdminExpertNotValidated( props ) {
    const { page } = useParams();


    const {getExpertRequests} = useContext(ContextP);
    
    const {validateExpertRequest} = useContext(ContextP);
    const {rejectExpertRequest} = useContext(ContextP);


    const [EXPERTS, setEXPERTS] = useState(null);
    const [rejectMotif, setRejectMotif] = useState(null);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [currentReject, setCurrentReject] = useState(null);

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment rejeter cette demande ?")) {
            let data = {
                reason: rejectMotif,
            };
            rejectExpertRequest(id, data, (res)=>{
                setCurrentRequest(null);
                setCurrentReject(null);
                setRejectMotif(null);
                // setALertMessage(false);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _validate_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment valider cette demande ?")) {
            validateExpertRequest(id, (res)=>{
                setCurrentRequest(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page) {
        let data = {
            validated: false,
        }
        getExpertRequests(data, 20,page,(res)=>{
            setEXPERTS(res.data);
            // console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [setEXPERTS])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_title'>
                    <span style={{color: "green"}}>
                        Validations en attentes ({EXPERTS!==null && EXPERTS.totalPossibleResult})
                    </span>
                </div>
                <br/>
                <div>
                    {
                        (EXPERTS!==null && EXPERTS.data.length>0) ?
                        <table className='table-classic'>
                            <thead>
                                <tr className='yel'>
                                    <th>NOM & PRENOM</th>
                                    <th>PAYS</th>
                                    <th>POSTE</th>
                                    <th>CV</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    EXPERTS!==null &&
                                    EXPERTS.data.map((expert, index)=>(
                                        <tr style={{
                                            background: index%2===0 ? "#00000005" : "transparent",
                                        }} className='yel'>
                                            <td><strong>{expert.person.lastName + " " + expert.person.firstName}</strong></td>
                                            <td style={{color: "#f05d5d"}}>
                                                <strong>{JSON.parse(expert.person.user.country).label }</strong>
                                            </td>
                                            <td>
                                                {expert.workStation} à {expert.workPlace}
                                            </td>
                                            <td style={{color: "#f05d5d"}}>
                                                <a target='blank_' href={PATH__.api_root + "medias/" + expert.resume.id + "/content" } alt={expert.person.lastName + " " + expert.person.firstName}>
                                                    lien
                                                </a>
                                            </td>
                                            {/* <td><div className='content' dangerouslySetInnerHTML={{__html: faq.response}}></div></td> */}
                                            <td>
                                                <button className='update'
                                                    onClick={(e)=>{
                                                        setCurrentRequest(expert);
                                                    }}
                                                >
                                                    voir
                                                </button>
                                                <button className='confirm'
                                                    onClick={(e)=>{
                                                        _validate_(expert.person.user.id);
                                                    }}
                                                >
                                                    confirmer
                                                </button>
                                                <button className='delete'
                                                    onClick={(e)=>{
                                                        setCurrentReject(expert);
                                                        setRejectMotif("");
                                                    }}
                                                >
                                                    rejeter
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        : null
                    }
                    <br/>
                    <br/>

                    {
                        EXPERTS!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={EXPERTS.totalNbPage} page={EXPERTS.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/expert/not/validated/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                </div>
            {
                currentRequest !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentRequest(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <div className="information_content_body">
                                <div className="information_content_body_head">
                                    <div className="information_content_body_head_dr">
                                        Demande
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Nom : </b><b className='info_name'>{currentRequest.person.lastName}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Prenom : </b><b className='info_name'>{currentRequest.person.firstName}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Civilité : </b><b className='info_name'>{currentRequest.person.civility}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Lieu de travail : </b><b className='info_name'>{currentRequest.workPlace}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Poste occupé : </b><b className='info_name'>{currentRequest.workStation}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>bref aperçu : </b>
                                    <div className="information_content_body_paragraph">
                                        <p dangerouslySetInnerHTML={{__html: currentRequest.shortDesc}}></p>
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Longue description : </b>
                                    <div className="information_content_body_paragraph">
                                        <p dangerouslySetInnerHTML={{__html: currentRequest.longDesc}}></p>
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>
                                        <a target='blank_' href={PATH__.api_root + "medias/" + currentRequest.resume.id + "/content" } alt={currentRequest.person.lastName + " " + currentRequest.person.firstName}>
                                            Curriculum Vitae
                                        </a>
                                    </b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Site web : </b><a href={currentRequest.webSite} >{currentRequest.webSite}</a>
                                </div>
                                
                                <div className="information_content_body_info">
                                    <b>Reseaux sociaux : </b>
                                    <ul>
                                        <li>
                                            - <span className='fa fa-facebook' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.facebookLink}>currentRequest.facebookLink</a>
                                        </li>
                                        <li>
                                            - <span className='fa fa-twitter' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.twitterLink}>currentRequest.twitterLink</a>
                                        </li>
                                        <li>
                                        </li>
                                            - <span className='fa fa-youtube' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.youtubeLink}>currentRequest.youtubeLink</a>
                                    </ul>
                                </div>
                                {/* <div className='information_content_body_cc'>
                                    <div className='information_content_body_cc_title'>
                                        Plainte(s) principale(s):
                                    </div>
                                    <ul>
                                        {currentRequest[persononstaté & évoquer par le patient"].map((cc, index) => (
                                            <li>
                                                - {cc}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                <br/>
                                <button className='button-style-1'>
                                    CONFIRMER
                                </button>
                            </div>
                        </div>
                    </section>
                :
                    null
            }
            </div>

            {
                rejectMotif !== null ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setRejectMotif(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Quels sont les raisons qui justifient le rejet ?
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' name='expertForm' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    _delete_(currentReject.person.user.id);
                                }}
                            >
                                
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Motif du refus</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={rejectMotif} onChange={(value) => {
                                            setRejectMotif(value)
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>veuillez être precis.</span>
                                    </div>
                                </div>
                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }
        </div>
    );
}

export default DashboardAdminExpertNotValidated;