import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function DashboardAdminExpertRejected( props ) {
    const { page } = useParams();

    const {getExpertRejected} = useContext(ContextP);

    const [currentRequest, setCurrentRequest] = useState(null);
    const [EXPERTS, setEXPERTS] = useState(null);


   
    function _get_(page) {
        getExpertRejected(20,page,(res)=>{
            setEXPERTS(res.data);
            // console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [setEXPERTS])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_title'>
                    <span style={{color: "red"}}>
                        Liste des experts rejetés ({EXPERTS!==null && EXPERTS.totalPossibleResult})
                    </span>
                </div>
                <br/>
                <div>
                    {
                        (EXPERTS!==null && EXPERTS.data.length>0) ?
                        <table className='table-classic'>
                            <thead>
                                <tr>
                                    <th>NOM & PRENOM</th>
                                    <th>PAYS</th>
                                    <th>POSTE</th>
                                    <th>CV</th>
                                    <th>MOTIFS DE REJET</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    EXPERTS!==null &&
                                    EXPERTS.data.map((expert, index)=>(
                                        <tr style={{
                                            background: index%2===0 ? "#00000005" : "transparent",
                                        }}>
                                            <td><strong>{expert.expert.person.lastName + " " + expert.expert.person.firstName}</strong></td>
                                            <td style={{color: "#f05d5d"}}>
                                                <strong>{JSON.parse(expert.expert.person.user.country).label }</strong>
                                            </td>
                                            <td>
                                                {expert.expert.workStation} à {expert.expert.workPlace}
                                            </td>
                                            <td style={{color: "#f05d5d"}}>
                                                <a target='blank_' href={PATH__.api_root + "medias/" + expert.expert.resume.id + "/content" } alt={expert.expert.person.lastName + " " + expert.expert.person.firstName}>
                                                    lien
                                                </a>
                                            </td>
                                            <td>
                                                <p dangerouslySetInnerHTML={{__html: expert.reason}}></p>
                                            </td>
                                            <td>
                                                <button className='update'
                                                    onClick={(e)=>{
                                                        setCurrentRequest(expert);
                                                    }}
                                                >
                                                    voir
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        : null
                    }
                    <br/>
                    <br/>
                    <br/>

                    {
                        EXPERTS!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={EXPERTS.totalNbPage} page={EXPERTS.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/expert/rejected/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                </div>
            {
                currentRequest !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentRequest(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <div className="information_content_body">
                                <div className="information_content_body_head">
                                    <div className="information_content_body_head_dr">
                                        Demande
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Nom : </b><b className='info_name'>{currentRequest.expert.person.lastName}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Prenom : </b><b className='info_name'>{currentRequest.expert.person.firstName}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Civilité : </b><b className='info_name'>{currentRequest.expert.person.civility}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Lieu de travail : </b><b className='info_name'>{currentRequest.expert.workPlace}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Poste occupé : </b><b className='info_name'>{currentRequest.expert.workStation}</b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>bref aperçu : </b>
                                    <div className="information_content_body_paragraph">
                                        <p dangerouslySetInnerHTML={{__html: currentRequest.expert.shortDesc}}></p>
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Longue description : </b>
                                    <div className="information_content_body_paragraph">
                                        <p dangerouslySetInnerHTML={{__html: currentRequest.expert.longDesc}}></p>
                                    </div>
                                </div>

                                <div className="information_content_body_info">
                                    <b>
                                        <a target='blank_' href={PATH__.api_root + "medias/" + currentRequest.expert.resume.id + "/content" } alt={currentRequest.expert.person.lastName + " " + currentRequest.expert.person.firstName}>
                                            Curriculum Vitae
                                        </a>
                                    </b>
                                </div>

                                <div className="information_content_body_info">
                                    <b>Site web : </b><a href={currentRequest.expert.webSite} >{currentRequest.expert.webSite}</a>
                                </div>
                                
                                <div className="information_content_body_info">
                                    <b>Reseaux sociaux : </b>
                                    <ul>
                                        <li>
                                            - <span className='fa fa-facebook' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.expert.facebookLink}>currentRequest.expert.facebookLink</a>
                                        </li>
                                        <li>
                                            - <span className='fa fa-twitter' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.expert.twitterLink}>currentRequest.expert.twitterLink</a>
                                        </li>
                                        <li>
                                        </li>
                                            - <span className='fa fa-youtube' style={{paddingRight: "5px"}}></span> : <a href={currentRequest.expert.youtubeLink}>currentRequest.expert.youtubeLink</a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminExpertRejected;