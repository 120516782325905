// import React, { useContext , useEffect} from 'react';

import React, {useContext, useEffect, useState} from 'react';

import {
    useParams
} from "react-router-dom";


// import { ContextP } from "../context/provider";

import '../styles/homeView.screen.style.scss';

/**Importation des images */

import { ContextP } from "../context/provider";


function Expert( props ) {
    // const { name } = useParams();
    const { id } = useParams();

    const {getOneExpert} = useContext(ContextP);
    const {getExpertServices_} = useContext(ContextP);

    const [EXPERT, setEXPERT] = useState(null);
    const [SERVICE, setSERVICE] = useState(null);

    function _get_(page) {
        getOneExpert(id ,(res)=>{
            setEXPERT(res.data);
            console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get__(id) {
        getExpertServices_(2000, 1, id, (res)=>{
            console.log(res);
            setSERVICE(res.data);
        }, (e)=>{
            return false;
        });
    }

    const generateServices = (list) => {
        return (
            <>
                {
                    list.map((service, index)=>(
                        <>
                            <div className="homeViewContent_section9_services_list_elt">
                                <div className="homeViewContent_section9_services_list_elt_head">
                                    <h2>
                                        {service.service.name}
                                        <span style={{color: service.service.payable ? "#ff5757" : "#5dab19"}} className="homeViewContent_section9_services_list_elt_head_tag">
                                            {service.service.payable ? "payant" : "gratuit"}
                                        </span>
                                    </h2>
                                </div>
                                <div className="homeViewContent_section9_services_list_elt_body">
                                    <p dangerouslySetInnerHTML={{__html: service.service.shortDesc}}></p>
                                </div>
                                <div className="homeViewContent_section9_services_list_elt_link">
                                    <a href={'/service/' + service.service.name + "/" + service.service.id}>En savoir plus</a>
                                </div>
                            </div>
                            
                            {service.children ? generateServices(service.children) : null}
                        </>
                    ))
                }
            </>
        )
    }

    useEffect(() => {
        _get_(id);
        _get__(id);
    }, [setEXPERT]);

    return (
        <div className='homeViewContent'>
            <div className='homeViewContent_section1'>
                <div className='AboutUsContent_section_back'></div>
                <div className='homeViewContent_section1_content'>
                    <div className="homeViewContent_section1_content_enc">
                        <span>Découvrez</span>
                    </div>
                    <div className='homeViewContent_section1_content_new'>
                        <div>
                            <h2>
                                {EXPERT !== null ? EXPERT.person.firstName + " " + EXPERT.person.lastName : ""}
                            </h2>
                        </div>
                        <div className='homeViewContent_section1_content_new_info'>
                            <div style={{textAlign: "left"}}>
                                <div>
                                    <a href='##' className='white_'>
                                        {EXPERT !== null ? EXPERT.person.user.email : ""}
                                    </a>
                                </div>
                                <div>
                                    <h3>
                                        {EXPERT !== null ? EXPERT.workStation : ""} à {EXPERT !== null ? EXPERT.workPlace : ""}
                                    </h3>
                                </div>
                                <div className='social'>
                                    <span className='fa fa-facebook' style={{paddingRight: "5px"}}></span>
                                    {EXPERT !== null ? EXPERT.facebookLink : ""}
                                </div>
                                <div className='social'>
                                    <span className='fa fa-twitter' style={{paddingRight: "5px"}}></span>
                                    {EXPERT !== null ? EXPERT.twitterLink : ""}
                                </div>
                                <div className='social'>
                                    <span className='fa fa-youtube' style={{paddingRight: "5px"}}></span>
                                    {EXPERT !== null ? EXPERT.youtubeLink : ""}
                                </div>
                                <div className='social'>
                                    <span className='fa fa-whatsapp' style={{paddingRight: "5px"}}></span>
                                    {EXPERT !== null ? EXPERT.whatsappLink : ""}
                                </div>
                                
                                <a className='button-style-1' href="##" >Prendre rendez-vous</a>
                            </div>
                            <div>
                                <img src={""} alt='erepere'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homeViewContent_section9_services' style={{marginTop: "-20px"}}>
                <div className="homeViewContent_section9_services_head">
                    <h2>
                        <span>SERVICES</span>
                    </h2>
                </div>

                <div className="homeViewContent_section9_services_list">
                    {SERVICE !== null && generateServices(SERVICE.data)}
                </div>
            </div>

            <div className='homeViewContent_section6_expert'>
                <br/>
                <br/>
                <div className="AboutUsContent_section_content_enc">
                    <span>À propos</span>
                </div>

                <div className='homeViewContent_section9_services_desc'>
                    {
                        EXPERT !== null ?
                            <p dangerouslySetInnerHTML={{__html: EXPERT.shortDesc}}></p>
                        : null
                    }
                </div>

                <div className='homeViewContent_section9_services_desc' >
                    {
                        EXPERT !== null ?
                            <p style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: EXPERT.longDesc}}></p>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default Expert;