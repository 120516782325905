import React from 'react';
import { SocialIcon } from 'react-social-icons'
// import { Link } from 'react-router-dom';

import './style/style.scss';
import './style/responsive.style.scss';


function ExpertCard(props){
    return (
        <div className="ExpertCard">
            <div className="ExpertCard_imageContainer">
                <a href={props.link}
                    onClick={() => {
                        props.action && props.action();
                    }}
                >
                    <img className="ExpertCard_image" alt={""} src={props.elt.img} />
                </a>
                {
                    props.elt.odd ?
                        <div className="ExpertCard_odd">
                            {
                                props.elt.odd.slice(0).reverse().map((odd, index) => (
                                    <div key={"ODD" + index} className="odd">
                                        <a href={"/#"}
                                            onClick={()=>{
                                                return true;
                                            }}
                                        >
                                            <img className="odd_" alt="odd" src={"./upload/logos/ODD/odd" +odd+ ".png"}/>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    :
                        null
                }
            </div>
            <div className="ExpertCard_body">
                <a href={props.link} style={{textDecoration: "none"}}
                    onClick={() => {
                        // props.action && props.action();
                    }}
                >
                    <div className="ExpertCard_body_title">
                        <span>{props.elt.person.firstName + " " + props.elt.person.lastName}</span>
                    </div>
                </a>
                
                <div className="ExpertCard_body_title ExpertCard_body_date">
                    <span>{props.elt.workStation} à {props.elt.workPlace}</span>
                </div>

                {
                    props.price &&
                        <div className={!props.price ? "ExpertCard_body_title ExpertCard_body_date" : "ExpertCard_body_price"}>
                            <span><label>{props.price && props.price} XOF</label> pour ce service</span>
                        </div>
                }

                
                
                <div className="Expertcard_separator_line"></div>
                <div className="ExpertCard_body_text text-form lineHeight1">
                    <p><div dangerouslySetInnerHTML={{__html: props.elt.shortDesc}}></div></p>
                </div>
                <div className="ExpertCard_competence">
                    {
                        props.elt.competences &&
                        props.elt.competences.map((competence, index) => (
                            <div key={"competences" + index} className="competence">
                                <a className="link" href={props.competenceLink}
                                    onClick={() => {
                                        // props.action2 && props.action2();
                                    }}
                                >
                                    <span>
                                        {competence.name}
                                    </span>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='ExpertCard_foot'>
                <a className="link" href={props.link}>
                    <span>Voir le profil</span>
                    <span className='ExpertCard_foot_fake'></span>
                </a>
                {/* <a className="link" href={props.link}>
                    <span>Prendre rendez-vous</span>
                    <span className='ExpertCard_foot_fake'></span>
                </a> */}
            </div>
            <div className='ExpertCard_social'>
                <SocialIcon fallback="facebook" url={props.elt.twitterLink} style={{width: "35px", height: "35px"}}/>
                <SocialIcon fallback="twitter" url={props.elt.twitterLink} style={{width: "35px", height: "35px", margin: "5px 5px"}}/>
                <SocialIcon fallback="youtube" url={props.elt.youtubeLink} style={{width: "35px", height: "35px", margin: "5px 5px"}}/>
                {/* <SocialIcon fallback="gmail" url={props.elt.twitterLink} style={{width: "35px", height: "35px", margin: "5px 5px"}}/> */}
                <SocialIcon fallback="whatsApp" url={props.elt.whatsappLink} style={{width: "35px", height: "35px", margin: "5px 5px"}}/>
            </div>
        </div>
    )
}

export default ExpertCard;