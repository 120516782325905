import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

class EXPERTRequest {
    getAllExperts(data, limit = 20, page = 1) {
        // console.log(data);
        return (

            axios({
                method: 'post',
                url: PATH__.api_root + "experts/list?limit=" + limit + "&page=" + page,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                data: {},
                url: PATH__.api_root + "experts/" + id,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    getRequest(data, limit = 20, page = 1) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "experts/list?limit=" + limit + "&page=" + page,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getRejected(limit = 20, page = 1) {
        // console.log(currentUser.key.authKey);
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "admin/experts/rejection?limit" + limit + "&page=" + page,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    validateExpertRequest(id) {
        return (
            axios({
                method: 'post',
                data: {},
                url: PATH__.api_root + "admin/experts/" + id + "/validate",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    rejectExpertRequest(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "admin/experts/" + id + "/reject",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getServices(expert_id, limit, page) {
        return (
            axios({
                method: 'post',
                data: {},
                url: PATH__.api_root + "services/experts/" + expert_id + "?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getServices_(expert_id, limit, page) {
        return (
            axios({
                method: 'post',
                data: {},
                url: PATH__.api_root + "services/experts/" + expert_id + "?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    getServicesAndRates(expert_id, limit, page) {
        return (
            axios({
                method: 'post',
                data: {},
                url: PATH__.api_root + "services/mine/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    detachService(service_id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "services/" + service_id + "/experts/detach",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    updateExpertInfos(data, resume = null) {
        // console.log(data);
        return (
            axios({
                method: 'patch',
                data: data,
                url: resume != null ? PATH__.api_root + "experts?resume=" + resume : PATH__.api_root + "experts",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }
}

export default EXPERTRequest;