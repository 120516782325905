import { createContext, useState } from "react"

import loader from '../assets/gif/loader.gif';

import FAQRequest from "../helpers/faq.requests.helper";
import CategoryRequest from "../helpers/category.requests.helper";
import ArticleRequest from "../helpers/article.requests.helper";
import MediaRequest from "../helpers/media.requests.helper";
import USERRequest from "../helpers/user.requests.helper";
import ServiceRequest from "../helpers/service.requests.helper";
import EXPERTRequest from "../helpers/expert.requests.helper";
import SEARCHRequest from "../helpers/search.requests.helper";

import Alert from '@mui/material/Alert';

export const ContextP = createContext();

const FAQ = new FAQRequest();
const CATEGORY = new CategoryRequest();
const ARTICLE = new ArticleRequest();
const MEDIA = new MediaRequest();
const USER = new USERRequest();
const SERVICE = new ServiceRequest();
const EXPERT = new EXPERTRequest();
const SEARCH = new SEARCHRequest();

// début du composant
/**
 * Cette page sert de vue parente.
 * Tous les affichages globeaux, les fonctions globals seront deposer ici 
 * et heriter par toutes les autres pages
 */
export default function Provider({ children }) {
    /**state */
    const [isLoading, setIsLoading] = useState(false);
    const [ALertMessage, setALertMessage] = useState(false);
    const [currentAlertMessage, setCurrentAlertMessage] = useState("");
    const [currentAlertType, setCurrentAlertType] = useState("warning");
    const [currentAlertAction, setCurrentAlertAction] = useState({action: ()=>{}});
    
    const logout = function() {
        localStorage.removeItem("erepere_user");
        window.location.reload();
    }

    const errorManager = function(e) {
        if(e.response ) {
            if(e.response.status) {
                if(e.response.status === 401) {
                    logout();
                }
            }
        }
    }
    
    /**Functions */
    /**----------------------------------------------- */
    const registration = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        USER.registration(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const login = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        USER.login(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const SendEXPERT_REQUEST = function (data, resume, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        USER.sendExpertRequest(data, resume).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("Demande envoyée avec succès. Veuillez patienter un instant...");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getUserInfo = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        USER.getUserInfo(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateUserInfo = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        USER.updateUserInfos(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------------------------------------------- */
    const getAllExpert = function (data, limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getAllExperts(data, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getExpertRequests = function (data, limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getRequest(data, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getOneExpert = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.GetOne (id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    // const getExpertNotValidated = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
    //     setIsLoading(true);
    //     EXPERT.getNotValidated(limit, page).then((response) => {
    //         setIsLoading(false);
    //         // console.log(response);
    //         if(response.status===200) {
    //             success(response);
    //         }
    //     }).catch((e) => {
    //         failed(e);
    //         errorManager(e);
    //         setIsLoading(false);
    //     });
    // }

    const getExpertRejected = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getRejected(limit, page).then((response) => {
            setIsLoading(false);
            // console.log(response);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const validateExpertRequest = function (expert_id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.validateExpertRequest(expert_id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("Validation effectuée");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const rejectExpertRequest = function (expert_id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.rejectExpertRequest(expert_id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("Refus effectué");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const detachExpertService = function (service_id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.detachService(service_id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }
    

    const getExpertServices = function (limit=200, page=1, expert_id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getServices(expert_id, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getExpertServices_ = function (limit=200, page=1, expert_id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getServices_(expert_id, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getExpertServicesAndRates = function (limit=200, page=1, expert_id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.getServicesAndRates(expert_id, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateExpertInfo = function (data, resume=null, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        EXPERT.updateExpertInfos(data, resume=null).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------------------------------------------- */
    const addFAQ = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        FAQ.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateFAQ = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        FAQ.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteFAQ = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        FAQ.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getFAQ = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        FAQ.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }


    /**----------------------------------------------- */
    const addCATEGORY = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateCATEGORY = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteCATEGORY = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        CATEGORY.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getCATEGORY = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }


    /**----------------------------------------------- */
    const addARTICLE = function (data, image, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.add(data, image, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const link_ARTICLE_MEDIA = function (data, articleId, mediaId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.linkMedia(data, articleId, mediaId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const unlink_ARTICLE_MEDIA = function (articleId, mediaIndex, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.unlinkMedia(articleId, mediaIndex).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateARTICLE = function (id, data, image, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.update(id, data, image, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteARTICLE_CATEGORIES = function (id, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.deleteCategories(id, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_BY_CATEGORIES = function (limit=20, page=1, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.getByCategories(limit, page, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_BY_NOT_CATEGORIES = function (limit=20, page=1, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.getByNotCategories(limit, page, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteARTICLE = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        ARTICLE.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getARTICLE = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getONE_ARTICLE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------- */
    const addSERVICE = function (data, image, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.add(data, image, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const validateSERVICE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.validate(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const link_SERVICE_MEDIA = function (data, serviceId, mediaId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.linkMedia(data, serviceId, mediaId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const unlink_SERVICE_MEDIA = function (serviceId, mediaIndex, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.unlinkMedia(serviceId, mediaIndex).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateSERVICE = function (id, data, image, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.update(id, data, image, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteSERVICE_CATEGORIES = function (id, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.deleteCategories(id, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getSERVICE_BY_CATEGORIES = function (limit=20, page=1, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.getByCategories(limit, page, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getSERVICE_BY_NOT_CATEGORIES = function (limit=20, page=1, category, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.getByNotCategories(limit, page, category).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteSERVICE = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        SERVICE.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getSERVICE = function (limit=20, page=1, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.GetAll(limit, page, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getSERVICE_ = function (limit=20, page=1, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.GetAll_(limit, page, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getONE_SERVICE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const submit_SERVICE_RATE = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.submitRate(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const update_SERVICE_RATE = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.updateRate(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const submit_SERVICE_FREE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.submitFree(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const GET_SERVICE_EXPERT_FREE = function (id, limit = 20, page = 1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.getExpertFree(id, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const GET_SERVICE_EXPERT_RATE = function (id, limit = 20, page = 1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SERVICE.getExpertRate(id, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------- */
    const addMEDIA = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                // setCurrentAlertMessage("SUCCESS");
                // setCurrentAlertType("success");
                // setALertMessage(true);
                // setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const updateMEDIA = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const deleteMEDIA = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        MEDIA.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    const getMEDIA = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------- */
    const search = function (searchValue, limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        SEARCH.search(searchValue, limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            errorManager(e);
            setIsLoading(false);
        });
    }

    return (
        <div>
            <ContextP.Provider
                value={{
                    isLoading, 
                    setIsLoading, 
                    addFAQ, 
                    getFAQ, 
                    updateFAQ,
                    deleteFAQ,
                    addCATEGORY, 
                    getCATEGORY, 
                    updateCATEGORY,
                    deleteCATEGORY,
                    addARTICLE, 
                    getARTICLE,
                    getONE_ARTICLE,
                    updateARTICLE,
                    deleteARTICLE_CATEGORIES,
                    getARTICLE_BY_CATEGORIES,
                    getARTICLE_BY_NOT_CATEGORIES,
                    deleteARTICLE,
                    addSERVICE,
                    validateSERVICE,
                    getSERVICE,
                    getSERVICE_,
                    getONE_SERVICE,
                    updateSERVICE,
                    deleteSERVICE_CATEGORIES,
                    getSERVICE_BY_CATEGORIES,
                    getSERVICE_BY_NOT_CATEGORIES,
                    deleteSERVICE,
                    submit_SERVICE_RATE,
                    update_SERVICE_RATE,
                    submit_SERVICE_FREE,
                    GET_SERVICE_EXPERT_FREE,
                    GET_SERVICE_EXPERT_RATE,
                    addMEDIA,
                    link_ARTICLE_MEDIA,
                    unlink_ARTICLE_MEDIA,
                    link_SERVICE_MEDIA,
                    unlink_SERVICE_MEDIA,
                    getMEDIA, 
                    updateMEDIA,
                    deleteMEDIA,
                    setALertMessage,
                    setCurrentAlertMessage,
                    setCurrentAlertType,
                    setCurrentAlertAction,
                    login,
                    registration,
                    SendEXPERT_REQUEST,
                    getUserInfo,
                    updateUserInfo,
                    getAllExpert,
                    getOneExpert,
                    getExpertRequests,
                    getExpertRejected,
                    validateExpertRequest,
                    rejectExpertRequest,
                    getExpertServices,
                    getExpertServices_,
                    getExpertServicesAndRates,
                    detachExpertService,
                    updateExpertInfo,
                    search,
                }}
            >
                {children}
                {
                    isLoading ?
                        <div>
                            <div className='globalALert_back'></div>
                                <div className='globalALert'>
                                    <div className='globalALert_content'>
                                        <div className='globalALert_content_loader'>
                                            <img src={loader} alt='freeJobs'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                }

                {
                    ALertMessage ?
                        <div>
                            <div className='globalALert_back'></div>
                            <div className='globalALert'>
                                <div className='globalALert_content'>
                                    <Alert style={{backgroundColor: "#ffffff"}} variant="outlined" elevation={6} severity={currentAlertType} onClose={() => {
                                        setALertMessage(false);
                                        currentAlertAction.action();
                                    }}>{currentAlertMessage}</Alert>
                                </div>
                            </div>
                        </div>
                    :
                        null
                }
            </ContextP.Provider>
        </div>
    )
}