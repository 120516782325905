import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

/**Importation des vues */
import Home from "../screens/home.screen";
import LoginView from '../screens/login.screen';
import RegistrationView from '../screens/registration.screen';
import AboutUs from "../screens/aboutUs.screen";
import Faq from "../screens/faq.screen";
import Category from "../screens/category.screen";
import Experts from "../screens/experts.screen";
import Expert from "../screens/expert.screen";
import ServiceScreen from "../screens/service.screen";
import SearchView from "../screens/search.screen";

import ErrorPage from "../screens/error.screen";

import DashboardAdmin from "../screens/dashboard.admin.screen";
import DashboardAdminFaq from "../screens/dashboard.admin.faq.screen";
import DashboardAdminCategory from "../screens/dashboard.admin.category.screen";
import DashboardAdminActuality from "../screens/dashboard.admin.actuality.screen";
import DashboardAdminArticle from "../screens/dashboard.admin.article.screen";
import DashboardAdminService from "../screens/dashboard.admin.service.screen";
import DashboardProfile from "../screens/dashboard.profile.screen";
import DashboardAdminExpertValidated from '../screens/dashboard.admin.expert.validated.screen';
import DashboardAdminExpertNotValidated from '../screens/dashboard.admin.expert.not.validated.screen';
import DashboardAdminExpertRejected from '../screens/dashboard.admin.expert.rejected.screen';
import DashboardExpertService from "../screens/dashboard.expert.service.screen";

const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

const router_ = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [
            {
                path: "/about-us",
                element: <AboutUs/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/login",
                element: <LoginView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/registration",
                element: <RegistrationView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/faq",
                element: <Faq/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/faq/:current",
                element: <Faq/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/actuality/:title/:id",
                element: <Faq/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/category/:name/:id/:type",
                element: <Category/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/category/:name/:id/:type/:page",
                element: <Category/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/experts",
                element: <Experts/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/experts/:current",
                element: <Experts/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/expert/:name/:id",
                element: <Expert/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/service/:name/:id",
                element: <ServiceScreen/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/service/:name/:id/:page",
                element: <ServiceScreen/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/search",
                element: <SearchView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/search/:value",
                element: <SearchView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/search/:value/:page",
                element: <SearchView/>,
                errorElement: <ErrorPage/>,
            }
        ],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/",
        element: <DashboardAdmin/>,
        children: [{
            path: "faq",
            element: !currentUser ? <LoginView/> : <DashboardAdminFaq/>
        },{
            path: "faq/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminFaq/>
        },{
            path: "category",
            element: !currentUser ? <LoginView/> : <DashboardAdminCategory/>
        },{
            path: "category/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminCategory/>
        },{
            path: "actuality",
            element: !currentUser ? <LoginView/> : <DashboardAdminActuality/>
        },{
            path: "actuality/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminActuality/>
        },{
            path: "article",
            element: !currentUser ? <LoginView/> : <DashboardAdminArticle/>
        },{
            path: "article/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminArticle/>
        },{
            path: "service",
            element: !currentUser ? <LoginView/> : <DashboardAdminService/>
        },{
            path: "service/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminService/>
        },{
            path: "profile",
            element: !currentUser ? <LoginView/> : <DashboardProfile/>
        },{
            path: "expert/validated",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertValidated/>
        },{
            path: "expert/validated/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertValidated/>
        },{
            path: "expert/not/validated",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertNotValidated/>
        },{
            path: "expert/not/validated/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertNotValidated/>
        },{
            path: "expert/rejected",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertRejected/>
        },{
            path: "expert/rejected/:page",
            element: !currentUser ? <LoginView/> : <DashboardAdminExpertRejected/>
        },{
            path: "expert/service",
            element: !currentUser ? <LoginView/> : <DashboardExpertService/>
        },]
    }
]);

const TEST = false;

function Route() {
    if(TEST) {
        return (
            <RouterProvider router={router_}/>
        );
    } else {
        return (
            <RouterProvider router={router_}/>
        );
    }
}
  
export default Route;