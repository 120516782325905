// import React, {useState, useEffect} from 'react';
import React, {useEffect, useState, useContext} from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import HomeView from './homeView.screen';
import AboutUs from './aboutUs.screen';
import LoginView from './login.screen';
import RegistrationView from './registration.screen';
import Faq from './faq.screen';
import Actuality from './actuality.screen';
import Category from './category.screen';
import Experts from './experts.screen';
import Expert from './expert.screen';
import ServiceScreen from './service.screen';
import SearchView from './search.screen';

/**Importation des composants */
import MenuComponent from '../components/menu/menu.component';

import '../styles/home.screen.style.scss';

import { ContextP } from "../context/provider";

/**Importation des helpers */
// import FixedOnScrollPage from '../helpers/fixe_to_head.helper';

function Home( props ) {

    const {getCATEGORY} = useContext(ContextP);

    const [isHidden, setIsHidden] = useState(false);
    const [SEARCH, setSEARCH] = useState(false);
    const [SEARCH_VALUE, setSEARCH_VALUE] = useState("");
    const [user, setUser] = useState("");
    const [list_menu, setList_menu] = useState([]);

    function handleScroll() {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollTop > 50) {
            // Scrolling down and passed the threshold
            setIsHidden(true);
        } else {
            // Scrolling up and passed the threshold
            setIsHidden(false);
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        const currentUser = JSON.parse(localStorage.getItem("erepere_user"));
        setUser(currentUser);

        let list = [{
            icon: null,
            name: "Informations",
            link: "##",
            action: (callback) => {
                callback();
                return true;
            },
            subMenus: [],
        }];
        getCATEGORY(20000,1,(res)=>{
            res.data.data.map((category) => {
                if(category.name !== "À la une" && category.name !== "Actualité") {
                    list[0].subMenus.push({
                        icon: null,
                        name: category.name,
                        link: "/category/" + category.name + "/" + category.id + "/articles",
                    });
                }
                return true;
            });

            list.push({
                icon: null,
                name: "Nous contacter",
                link: "##",
                action: (callback) => {
                    callback();
                    return true;
                }
            });
            list.push({
                icon: null,
                name: "À propos de nous",
                link: "/about-us",
                action: (callback) => {
                    callback();
                    return true;
                }
            });
            list.push({
                icon: null,
                name: "Demander un service",
                link: "/experts",
                action: (callback) => {
                    callback();
                    return true;
                },
            });
            list.push({
                icon: null,
                name: "FAQ",
                link: "/faq",
                action: (callback) => {
                    callback();
                    return true;
                },
            });

            setList_menu(list);
        }, (e)=>{
            return false;
        });
    }, []);
    // useEffect(() => {
    //     const currentUser = JSON.parse(localStorage.getItem("user"));
    // },[]);
    return (
        <>
            <header>
                <nav className={"nav top_nav " + (isHidden ? " is_hidden" : " is_visible")}>
                    <div>
                        <ul>
                            <li>
                                <a className='home' href='/'>
                                    <span>ə</span>
                                    <span>R</span>
                                    <span>e</span>
                                    <span>p</span>
                                    <span>e</span>
                                    <span>r</span>
                                    <span>e</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='search_z' style={{
                        display: SEARCH ? "flex" : "none",
                        width: SEARCH ? "100%" : "0px"
                    }}>
                        <input type='search' value={SEARCH_VALUE} onChange={(e)=>{
                            setSEARCH_VALUE(e.target.value);
                        }}/>
                        <a href={'/search/' + SEARCH_VALUE }  role="button" className="fa fa-search"> </a>
                        <span role='button' className='fa fa-close'
                            onClick={(e) => {
                                setSEARCH(false)
                            }}
                        ></span>
                    </div>
                    <div>
                        <ul className='top_nav_connection'
                            style={{
                                display: SEARCH ? "none" : "flex"
                            }}
                        >
                            {
                                user === null ?
                                    <>
                                        <li>
                                            <a style={{borderRadius: "5px"}} className='button-style-1 connection' href='/login'>
                                                SE CONNECTER
                                            </a>
                                        </li>
                                        <li>
                                            <a style={{borderRadius: "5px"}} className='button-style-1 connection' href='/registration'>
                                                S'INSCRIRE
                                            </a>
                                        </li>
                                    </>
                                :
                                    <>
                                        <li>
                                            <a style={{borderRadius: "5px", textDecoration: "underline", fontSize: "1em"}} className='button-style-2 connection' href='/dashboard'>
                                                Dashboard
                                            </a>
                                        </li>
                                    </>
                            }
                            
                            <li>
                                <a className='button-style-2 search' href='##'
                                    onClick={(e)=>{
                                        setSEARCH(true);
                                    }}
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="nav kando-header bottom_nav">
                    <div></div>
                    <MenuComponent list_menu={list_menu} />
                </div>
            </header>
            <Routes>
                <Route path='/login' element={<LoginView/>}/>
                <Route path='/registration' element={<RegistrationView/>}/>
                <Route path='/about-us' element={<AboutUs/>}/>
                <Route path='/faq' element={<Faq/>}/>
                <Route path='/faq/:current' element={<Faq/>}/>
                <Route path='/actuality/:title/:id' element={<Actuality/>}/>
                <Route path='/category/:name/:id/:type' element={<Category/>}/>
                <Route path='/category/:name/:id/:type/:page' element={<Category/>}/>
                <Route path='/experts' element={<Experts/>}/>
                <Route path='/experts/:current' element={<Experts/>}/>
                <Route path='/expert/:name/:id' element={<Expert/>}/>
                <Route path='/service/:name/:id' element={<ServiceScreen/>}/>
                <Route path='/service/:name/:id/:page' element={<ServiceScreen/>}/>
                <Route path='/search' element={<SearchView/>}/>
                <Route path='/search/:value' element={<SearchView/>}/>
                <Route path='/search/:value/:page' element={<SearchView/>}/>
                <Route path='*' element={<HomeView/>}/>
            </Routes>
            <footer className="footer">
                <div className="site-footer__upper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__about">
                                    <p></p>
                                    <div className="footer-widget__social">
                                        {/* <img src="/rad-logo.png" alt='RAD-ONG'/> */}
                                        <a href="./" className="nav_icon">
                                            <div className="nav_icon_"></div>
                                            <div className="nav_icon_text">
                                                <div className="nav_icon_text_name">
                                                <ul>
                                                    <li>
                                                        <a className='home' href='/'>
                                                            <span>ə</span>
                                                            <span>R</span>
                                                            <span>e</span>
                                                            <span>p</span>
                                                            <span>e</span>
                                                            <span>r</span>
                                                            <span>e</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div className="nav_icon_text_slug">
                                                    <span>
                                                        {  }
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__links__2">
                                    <h3 className="footer-widget__title">Liens utiles</h3>
                                    <ul className="list-unstyled footer-widget__links-list">
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Informations</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Nous contacter</a></li>                                            
                                        <li><a href="/about-us"><span className="fa fa-chevron-right mr-2"></span>À propos de nous</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Nous contacter</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Demander un service</a></li>
                                        <li><a href="/faq"><span className="fa fa-chevron-right mr-2"></span>FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__links__2">
                                    {/* <h3 className="footer-widget__title"></h3> */}
                                    <ul className="list-unstyled footer-widget__links-list">
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Mentions légales</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Politique de confidentialité</a></li>                                            
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Conditions Générales de Ventes</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Règles Générales de Protection des Données</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-contacts footer-widget footer-widget__contact">
                                    <h3 className="footer-widget__title">Contact</h3>
                                    <p><a href={"tel:"}><i className="fa fa-phone"></i>{ "+229000000" }</a></p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="container">
                        <p></p>
                        <span>
                            © 2024 <strong>eRepere</strong> .Tous droits réservés
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;