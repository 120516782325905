// import React, { useContext , useEffect} from 'react';

import React, {useContext, useEffect, useState} from 'react';

import {
    useParams
} from "react-router-dom";

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

// import { ContextP } from "../context/provider";

import '../styles/aboutUs.screen.style.scss';

import { ContextP } from "../context/provider";

/**Importation des images */


import Card from '../components/card/card.component';

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();


function Category( props ) {
    const { name } = useParams();
    const { id } = useParams();
    const { type } = useParams();
    const { page } = useParams();

    const {getCATEGORY} = useContext(ContextP);
    const {getARTICLE_BY_CATEGORIES} = useContext(ContextP);
    // const {getARTICLE_BY_NOT_CATEGORIES} = useContext(ContextP);

    const [TYPE, setTYPE] = useState(null);
    const [NAME, setNAME] = useState(null);
    const [ID, setID] = useState(null);
    const [LIST, setLIST] = useState(null);
    const [CAT, setCAT] = useState(null);

    function _get_by(limit=10, page=1, categories, call=()=>{}) {
        getARTICLE_BY_CATEGORIES(limit, page, categories,(res)=>{
            // console.log(res.data);
            call(res.data);
        }, (e)=>{
            return false;
        });
    }

    // function _get_by_not(limit=10, page=1, categories, call=()=>{}) {
    //     getARTICLE_BY_NOT_CATEGORIES(limit, page, categories,(res)=>{
    //         // console.log(res.data);
    //         call(res.data);
    //     }, (e)=>{
    //         return false;
    //     });
    // }

    useEffect(() => {
        if(type) {
            setTYPE(type);
        }
        if(name) {
            setNAME(name);
        }
        if(id) {
            setID(id);
        }

        getCATEGORY(20000,1,(res)=>{
            let cat = [id];
            if(type === "actualities") {
                res.data.data.map((category) => {
                    if(category.name == "Actualité") {
                        cat.push(category.id);
                    }
                    return true;
                });
                setCAT(cat);
                let data = {
                    categories: {
                        in: {
                            all: true,
                            categories: cat,
                        },
                    },
                    state: 'Published'
                };
                _get_by(20, (page ? page : 1), data, (res)=>{
                    // console.log(res);
                    setLIST(res);
                });
            } else {
                cat = [];
                res.data.data.map((category) => {
                    if(category.name == "Actualité") {
                        cat.push(category.id);
                    }
                    return true;
                });
                setCAT(cat);
                let data = {
                    categories: {
                        in: {
                            all: true,
                            categories: [id],
                        },
                        ni: {
                            categories: cat
                        }
                    },
                    state: 'Published'
                };
                _get_by(20, (page ? page : 1), data, (res)=>{
                    console.log(cat);
                    setLIST(res);
                });
            }
        }, (e)=>{
            return false;
        });
    }, [setTYPE, setNAME, setID])
    
    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>#
                                {
                                    NAME !== null && NAME
                                }
                            </h2>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='AboutUsContent_section3'>
                <div className='AboutUsContent_section3_back'></div>
                <div className='AboutUsContent_section3_content'>
                    <div className="AboutUsContent_section3_content_enc">
                        <span>Ce que nous offrons</span>
                    </div>
                    <div className='AboutUsContent_section3_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section3_content_text_p'>
                            <p>
                                Sur erepere.fr, vous trouverez une mine d'informations précieuses sur la fiscalité, les réglementations, les procédures douanières et les opportunités d'investissement au Bénin. Notre équipe d'experts-conseils est là pour répondre à vos questions spécifiques, vous guider à travers les défis potentiels et vous aider à prendre des décisions éclairées.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='homeViewContent_section6_expert'>
                <div className='homeViewContent_section6_expert_text'>
                    <div>
                        <p></p>
                        <a href={'/category/' + NAME + "/" + ID + "/actualities"} className={TYPE==="actualities" ? "p_c_current" : ""}>
                            ACTUALITÉS
                        </a>
                        <a href={'/category/' + NAME + "/" + ID + "/articles"} className={TYPE==="articles" ? "p_c_current" : ""}>
                            ARTICLES
                        </a>
                    </div>
                    <div>
                        <p></p>
                        <h2>
                            #{NAME}
                        </h2>
                    </div>
                    {/* <div></div> */}
                </div>

                <div className="homeViewContent_section4_content_list">
                    {
                        LIST !== null &&
                        LIST.data.map((news, index)=>(
                            <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                <Card elt={news} link={"/actuality/"+news.title+"/"+news.id} imgPath={news.image ? (PATH__.api_root + "medias/" + news.image.id + "/content") : ""}/>
                            </div>
                        ))
                    }
                </div>

                <br/>
                {
                    LIST!==null &&
                    <Box
                        sx={{
                            margin: "auto",
                            width: "fit-content",
                            alignItems: "center",
                        }}
                    >
                        <Pagination count={LIST.totalNbPage} page={LIST.page} 
                            onChange={(e, value)=>{
                                if(type === "actualities") {
                                    let data = {
                                        categories: {
                                            in: {
                                                all: true,
                                                categories: CAT,
                                            },
                                        },
                                    };
                                    _get_by(20, value, data, (res)=>{
                                        setLIST(res);
                                    });
                                } else {
                                    let data = {
                                        categories: {
                                            in: {
                                                all: true,
                                                categories: [id],
                                            },
                                            ni: {
                                                categories: CAT
                                            }
                                        },
                                    };
                                    _get_by(20, value, data, (res)=>{
                                        setLIST(res);
                                    });
                                }
                                window.history.replaceState({}, '', '/category/' + NAME + "/" + ID + "/" + TYPE + "/" + value);
                            }} variant="outlined" color="primary" />
                    </Box>
                }

                {/* <div className='homeViewContent_section5_expert_button'>
                    <a href='##' className='button-style-2'>
                        VOIR TOUS LES EXPERTS ET DEMMANDER UN RENDEZ-VOUS
                    </a>
                </div> */}
            </div>
        </div>
    );
}

export default Category;