import React, {useState, useContext, useEffect} from 'react';
// import { Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../styles/faq.screen.style.scss'; 

import { ContextP } from "../context/provider";

export default function Faq(){
    const {getFAQ} = useContext(ContextP);

    const [FAQ, setFAQ] = useState([]);

    useEffect(() => {
        getFAQ(1000,1,(res)=>{
            setFAQ(res.data.data);
        }, (e)=>{
            return false;
        });
    },[setFAQ]);

    return (
        <div className='homeViewContent faqs'>
            <div className='homeViewContent_section faqsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='homeViewContent_section1_content faqsContent_section_'>
                    {
                        FAQ.map((faq) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={faq.question}
                                    id={faq.question}
                                >
                                    <h2 className='faq_title'>{faq.question}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div dangerouslySetInnerHTML={{__html: faq.response}}></div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}