import React, {useState, useEffect, useContext, useMemo} from 'react';

// import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ReactCountryFlagsSelect from 'react-country-flags-select';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

// import Select from 'react-select';
// import countryList from 'react-select-country-list';

import '../styles/dashboard.profile.style.scss'; 

import Verifier from '../helpers/verifier.helper';

import { ContextP } from "../context/provider";

const VERF = new Verifier();

function DashboardProfile( props ) {
    // const {getSERVICE} = useContext(ContextP);
    const {SendEXPERT_REQUEST} = useContext(ContextP);
    const {addMEDIA} = useContext(ContextP);
    const {getUserInfo} = useContext(ContextP);
    const {getOneExpert} = useContext(ContextP);

    const {updateUserInfo} = useContext(ContextP);
    const {updateExpertInfo} = useContext(ContextP);
    
    
    
    // const [SERVICE, setSERVICE] = useState([]);
    // const [SERVICE_selected, setSERVICE_selected] = useState([]);
    const [user, setUser] = useState(null);
    const [user_, setUser_] = useState(null);
    const [EXPERT, setEXPERT] = useState(null);
    const [infoExpert, setInfoExpert] = useState({
        person: {
            firstName: null,
            lastName: null,
            civility: "monsieur",
        },
        workStation: null,
        workPlace: null,
        // workLocation: null,
        shortDesc: null,
        longDesc: null,
        webSite: null,
        facebookLink: null,
        twitterLink: null,
        whatsappLink: null,
        youtubeLink: null,
    });
    const [password, setPassword] = useState(null);
    const [userName, setUserName] = useState(null);
    const [updateType, setUpdateType] = useState(null);
    
    const [password_verification_results, setpassword_verification_results] = useState(
        {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
            isOk: false,
            isConfirmed: false,
        }
    );

    const [countrySelected, setCountrySelected] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");

    // function _get_(page) {
    //     getSERVICE(2000, page, (res)=>{
    //         // console.log(res);
    //         setSERVICE(res.data.data);
    //     }, (e)=>{
    //         return false;
    //     });
    // }

    function _makeExpertRequest_() {
        let media = new FormData();
        media.append("alt", "actualité erepere");
        media.append("description", "");
        media.append("file", document.forms["expertForm"]["file"].files[0]);

        addMEDIA(media, (res)=>{
            // console.log(infoExpert);
            SendEXPERT_REQUEST(infoExpert, res.data.id, (res)=>{
                // console.log(res);
            }, (e)=>{
                console.log(e);
                return false;
            });
        }, (e)=>{
            
        })
    }
    
    function _updateUserInfo_() {
        let data = {
            email: document.forms["personalForm"]["email"].value,
            country: JSON.stringify(countrySelected),
            cellphone: phoneNumber,
        };
        // console.log(countrySelected);
        updateUserInfo(data ,(res)=>{
            setUpdateType(null);
            _get_userInfo(user.key.user.id);
        }, (e)=>{
            return false;
        });
    }

    function _updateExpertInfos_() {
        if(document.forms["expertForm"]["file"].files.length>0) {
            let media = new FormData();
            media.append("alt", "actualité erepere");
            media.append("description", "");
            media.append("file", document.forms["expertForm"]["file"].files[0]);

            addMEDIA(media, (res)=>{
                // console.log(infoExpert);
                updateExpertInfo(infoExpert, res.data.id, (res)=>{
                    _get_userInfo(user.key.user.id);
                    if(user.key && user.key.roles !== null && user.key.roles.includes('Expert')) {
                        _get_expertInfo(user.key.user.id);
                    } else if(user.key && user.key.roles === null) {
                        _get_expertInfo(user.key.user.id);
                    }
                }, (e)=>{
                    console.log(e);
                    return false;
                });
            }, (e)=>{
                
            })
        } else {
            // console.log(infoExpert);
            updateExpertInfo(infoExpert, null, (res)=>{
                _get_userInfo(user.key.user.id);
                if(user.key && user.key.roles !== null && user.key.roles.includes('Expert')) {
                    _get_expertInfo(user.key.user.id);
                } else if(user.key && user.key.roles === null) {
                    _get_expertInfo(user.key.user.id);
                }
            }, (e)=>{
                console.log(e);
                return false;
            });
        }
    }

    function _get_userInfo(id) {
        getUserInfo(id, (res)=>{
            // console.log(res);
            setUser_(res.data);
            if(res.data.country){
                // console.log(JSON.parse(res.data.country));
                setCountrySelected(JSON.parse(res.data.country));
            }
            setPhoneNumber(res.data.cellphone);
            
        }, (e)=>{
            return false;
        });
    }

    function _get_expertInfo(id) {
        getOneExpert(id ,(res)=>{
            setEXPERT(res.data);
            setInfoExpert(res.data);
            // console.log(res.data);
        }, (e)=>{
            return false;
        });
    }

    // function _get_expertInfo_(id) {
    //     getOneExpert(id ,(res)=>{
    //         console.log(res.data);
    //     }, (e)=>{
    //         return false;
    //     });
    // }

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("erepere_user"));
        console.log(currentUser);
        // console.log(options);
        setUser(currentUser);
        // _get_(1);
        _get_userInfo(currentUser.key.user.id);

        if(currentUser.key && currentUser.key.roles !== null && currentUser.key.roles.includes('Expert')) {
            _get_expertInfo(currentUser.key.user.id);
        } else if(currentUser.key && currentUser.key.roles === null) {
            _get_expertInfo(currentUser.key.user.id);
        }
    },[setEXPERT, setUser, setUser_]);

    return (
        <div className='profile'>
            <div className="profile_content">
                <div action='#' method='POST' className='form'>
                    <div className="profile_content_section">
                        <div className="profile_content_section_">
                            <h2>Identité
                            <svg version="1.1" fill='currentColor' id="designs" width="1em" height="1em" viewBox="0 0 32 32">
                            <path class="sketchy_een" d="M21.649,20.727c0.086,0.851,0.17,1.854,0.134,2.839c-0.025,0.724-0.085,1.443-0.145,2.163l-0.055,0.665
                                c-0.029,0.341-0.168,0.587-0.414,0.732c-0.126,0.074-0.273,0.113-0.421,0.113c-0.074,0-0.148-0.01-0.217-0.03
                                c-0.207-0.056-0.388-0.196-0.498-0.382c-0.109-0.185-0.133-0.38-0.082-0.652c0.024-0.123,0.044-0.247,0.063-0.371
                                c0.201-1.76,0.184-3.626-0.05-5.551c-0.076-0.537-0.162-1.105-0.306-1.656c-0.148-0.557-0.323-1.09-0.52-1.585
                                c-0.309-0.718-0.59-1.236-0.911-1.683c-0.175-0.21-0.351-0.395-0.55-0.566c-0.235-0.161-0.486-0.296-0.801-0.437
                                c-0.333-0.128-0.662-0.219-1.012-0.284c-0.108-0.009-0.2-0.013-0.29-0.013c-0.082,0-0.164,0.003-0.247,0.01
                                c-0.26,0.046-0.497,0.109-0.733,0.192c-0.224,0.107-0.421,0.222-0.614,0.354c-0.117,0.103-0.209,0.199-0.298,0.302
                                c-0.092,0.132-0.164,0.255-0.228,0.385c-0.09,0.237-0.158,0.46-0.204,0.693c-0.018,0.259-0.009,0.495,0.013,0.733
                                c0.079,0.424,0.203,0.824,0.325,1.211c0.119,0.38,0.242,0.774,0.315,1.169c0.18,0.984,0.286,1.918,0.325,2.855
                                c0.045,1.04,0.029,1.967-0.052,2.832c-0.113,1.236-0.365,2.162-0.793,2.915c-0.147,0.257-0.431,0.417-0.741,0.417
                                c-0.15,0-0.296-0.039-0.423-0.113c-0.401-0.235-0.538-0.757-0.305-1.165c0.075-0.132,0.144-0.263,0.208-0.398
                                c0.159-0.441,0.27-0.881,0.345-1.357c0.08-0.698,0.114-1.471,0.105-2.37c-0.01-0.807-0.057-1.544-0.141-2.255
                                c-0.118-0.76-0.243-1.329-0.408-1.85l-0.109-0.326c-0.055-0.161-0.11-0.321-0.155-0.484c-0.083-0.312-0.145-0.652-0.194-1.068
                                c-0.127-1.04,0.198-2.124,0.869-2.901c0.574-0.669,1.479-1.151,2.482-1.321c0.228-0.038,0.454-0.058,0.679-0.058
                                c0.307,0,0.616,0.038,0.919,0.112c0.458,0.111,0.919,0.279,1.411,0.515c0.494,0.235,0.894,0.517,1.19,0.837
                                c0.351,0.38,0.653,0.793,0.898,1.227c0.499,0.877,0.88,1.776,1.135,2.672C21.429,18.773,21.555,19.782,21.649,20.727z M10.44,9.732
                                c-0.079-0.022-0.168-0.033-0.258-0.033c-0.175,0-0.332,0.042-0.443,0.12c-0.268,0.187-0.493,0.37-0.687,0.559
                                c-0.21,0.207-0.39,0.454-0.548,0.673c-0.229,0.313-0.443,0.698-0.636,1.145c-0.208,0.476-0.417,0.983-0.497,1.49
                                c-0.045,0.29-0.084,0.58-0.107,0.872c-0.018,0.225,0,0.456,0.023,0.768c0.036,0.541,0.113,1.066,0.228,1.562
                                c0.099,0.427,0.244,0.851,0.409,1.339c0.377,1.115,0.632,2.051,0.802,2.946c0.022,0.12,0.042,0.241,0.06,0.368
                                c0.039,0.392,0.07,0.785,0.082,1.181c0.012,0.438-0.021,0.877-0.059,1.335c-0.072,0.463-0.164,0.922-0.256,1.384
                                c-0.053,0.255-0.027,0.458,0.08,0.641c0.108,0.184,0.286,0.322,0.489,0.377c0.063,0.017,0.131,0.026,0.201,0.026
                                c0.339,0,0.733-0.22,0.805-0.588l0.024-0.125c0.087-0.459,0.176-0.921,0.227-1.388c0.047-0.431,0.072-0.865,0.085-1.301
                                c0.023-0.755-0.038-1.578-0.193-2.59c-0.12-0.776-0.33-1.549-0.534-2.297c-0.195-0.717-0.397-1.458-0.522-2.214
                                c-0.056-0.456-0.113-1.057-0.056-1.687c0.07-0.378,0.177-0.757,0.332-1.166c0.215-0.469,0.441-0.868,0.7-1.232
                                c0.173-0.197,0.359-0.383,0.561-0.553l0,0c0.188-0.156,0.285-0.295,0.336-0.48c0.064-0.237,0.03-0.495-0.093-0.708
                                C10.869,9.951,10.668,9.796,10.44,9.732z M17.769,22.728c-0.024-0.455-0.071-0.911-0.122-1.367c-0.062-0.578-0.127-1.062-0.24-1.539
                                c-0.102-0.429-0.226-0.849-0.352-1.269c-0.135-0.451-0.259-0.903-0.382-1.356l-0.049-0.178c-0.093-0.346-0.426-0.597-0.791-0.597
                                c-0.071,0-0.142,0.009-0.21,0.028c-0.207,0.057-0.381,0.194-0.49,0.386c-0.109,0.192-0.138,0.411-0.081,0.617
                                c0.146,0.527,0.31,1.047,0.474,1.567l0.129,0.412c0.158,0.508,0.282,1.071,0.38,1.726c0.091,0.822,0.146,1.582,0.167,2.327
                                c0.021,0.677-0.019,1.396-0.125,2.265c-0.082,0.601-0.213,1.388-0.501,2.138c-0.078,0.202-0.012,0.49,0.093,0.671
                                c0.117,0.199,0.31,0.347,0.531,0.408C16.274,28.99,16.352,29,16.43,29c0.399,0,0.754-0.269,0.863-0.654
                                c0.23-0.809,0.369-1.717,0.435-2.857l0.02-0.317c0.022-0.351,0.044-0.702,0.054-1.054c0.015-0.41-0.006-0.82-0.026-1.231
                                L17.769,22.728z M12.148,8.488c-0.103,0.176-0.13,0.389-0.077,0.585c0.052,0.19,0.179,0.355,0.35,0.453
                                c0.112,0.066,0.228,0.096,0.366,0.096c0.076,0,0.154-0.009,0.235-0.023l0.209-0.036c0.295-0.051,0.591-0.102,0.891-0.143
                                c0.279-0.032,0.558-0.049,0.837-0.049c0.19,0,0.379,0.008,0.583,0.026c0.496,0.096,1.041,0.253,1.676,0.485
                                c0.376,0.173,0.688,0.356,0.933,0.546c0.136,0.079,0.279,0.118,0.437,0.118c0.228,0,0.451-0.092,0.61-0.251
                                c0.161-0.161,0.253-0.384,0.253-0.612c0-0.2-0.083-0.477-0.238-0.597c-0.398-0.309-0.752-0.524-1.115-0.676
                                c-0.419-0.178-0.847-0.319-1.309-0.432c-0.385-0.094-0.842-0.193-1.316-0.214L15.363,7.76c-0.309,0-0.627,0.05-0.935,0.099
                                l-0.055,0.008l0,0.006c-0.596,0.077-1.191,0.17-1.786,0.268C12.417,8.17,12.234,8.34,12.148,8.488z M25.477,18.611
                                c-0.068-0.477-0.166-0.936-0.29-1.365c-0.136-0.468-0.327-0.927-0.512-1.37c-0.228-0.55-0.451-1.027-0.683-1.459
                                c-0.177-0.333-0.393-0.647-0.607-0.958l-0.198-0.289c-0.229-0.34-0.447-0.684-0.665-1.029l-0.098-0.155
                                c-0.17-0.266-0.462-0.431-0.763-0.431c-0.149,0-0.291,0.039-0.423,0.116c-0.2,0.117-0.352,0.33-0.408,0.57
                                c-0.051,0.22-0.019,0.435,0.093,0.606c0.197,0.298,0.4,0.592,0.604,0.886c0.161,0.233,0.322,0.466,0.48,0.701
                                c0.313,0.47,0.59,0.985,0.871,1.626c0.209,0.514,0.448,1.123,0.633,1.751c0.183,0.617,0.289,1.269,0.372,1.835
                                c0.242,1.962,0.29,3.578,0.151,5.09c-0.02,0.203,0.112,0.435,0.242,0.566c0.152,0.153,0.364,0.241,0.58,0.241
                                c0.505,0,0.795-0.418,0.822-0.831l0.014-0.217c0.028-0.41,0.055-0.819,0.05-1.229c-0.007-0.511-0.017-1.021-0.036-1.533
                                C25.675,20.734,25.63,19.674,25.477,18.611z M28.417,19.511c-0.013-0.866-0.149-1.72-0.295-2.515
                                c-0.035-0.196-0.215-0.381-0.368-0.47c-0.125-0.072-0.266-0.11-0.41-0.11c-0.073,0-0.145,0.01-0.216,0.03
                                c-0.202,0.054-0.376,0.189-0.482,0.373c-0.107,0.178-0.13,0.368-0.079,0.634c0.08,0.424,0.152,0.847,0.217,1.28
                                c0.044,0.449,0.048,0.89,0.04,1.414l-0.006,0.44c-0.003,0.34-0.007,0.68-0.022,1.019c-0.008,0.191,0.066,0.379,0.21,0.531
                                c0.156,0.164,0.377,0.261,0.591,0.261c0.21,0,0.417-0.086,0.565-0.235c0.161-0.161,0.209-0.341,0.234-0.581
                                c0.033-0.286,0.03-0.573,0.027-0.862C28.422,20.721,28.421,19.871,28.417,19.511z M26.946,13.889
                                c0.115-0.213,0.14-0.446,0.072-0.657c-0.057-0.176-0.125-0.346-0.194-0.516l-0.325-0.839c-0.209-0.519-0.437-1.068-0.716-1.581
                                c-0.492-0.904-1.193-1.679-1.912-2.474c-0.3-0.333-0.618-0.65-1-1.017c-0.378-0.366-0.77-0.745-1.196-1.065
                                c-0.398-0.299-0.871-0.504-1.29-0.685c-0.564-0.246-1.13-0.483-1.7-0.713l-0.113-0.046c-0.856-0.347-1.74-0.706-2.652-0.895
                                c-0.535-0.111-1.089-0.173-1.697-0.242l-0.32-0.036c-0.407-0.045-0.812-0.091-1.222-0.116C12.59,3.003,12.5,3,12.409,3
                                c-0.446,0-0.9,0.066-1.403,0.139c-0.498,0.072-0.998,0.203-1.485,0.39c-0.586,0.224-1.09,0.461-1.542,0.723
                                C7.54,4.506,7.08,4.824,6.531,5.253C5.999,5.668,5.6,6.225,5.237,6.761c-0.255,0.377-0.485,0.794-0.7,1.275
                                C4.261,8.643,4.049,9.201,3.89,9.742c-0.153,0.515-0.25,1.079-0.298,1.725c-0.033,0.454-0.011,0.956,0.07,1.579
                                c0.084,0.629,0.165,1.195,0.301,1.752c0.082,0.336,0.175,0.669,0.268,1.004l0.152,0.552c0.082,0.303,0.155,0.607,0.229,0.911
                                l0.13,0.534c0.161,0.651,0.302,1.239,0.401,1.868c0.053,0.554,0.062,1.023,0.031,1.477c-0.031,0.44-0.079,0.879-0.126,1.317
                                l-0.039,0.364c-0.021,0.2,0.109,0.446,0.248,0.585c0.16,0.158,0.378,0.248,0.6,0.248c0.223,0,0.442-0.09,0.599-0.247
                                c0.174-0.174,0.226-0.372,0.25-0.617l0.016-0.158c0.079-0.81,0.161-1.646,0.111-2.466c-0.05-0.831-0.202-1.655-0.348-2.452
                                c-0.139-0.76-0.282-1.516-0.461-2.27c-0.167-0.707-0.34-1.472-0.469-2.274c-0.084-0.709-0.103-1.358-0.056-1.999
                                c0.112-0.658,0.3-1.328,0.578-2.055c0.145-0.334,0.299-0.662,0.476-0.983C6.76,7.764,7.012,7.41,7.242,7.086
                                C7.417,6.884,7.62,6.69,7.909,6.45c0.687-0.502,1.29-0.865,1.906-1.147c0.545-0.209,1.128-0.359,1.79-0.459
                                c0.265-0.024,0.529-0.038,0.793-0.038c0.24,0,0.479,0.011,0.72,0.035c0.481,0.044,0.961,0.102,1.445,0.164
                                c0.369,0.06,0.738,0.127,1.103,0.206c0.304,0.065,0.595,0.168,0.886,0.273l0.151,0.053c0.724,0.256,1.436,0.535,2.161,0.827
                                c0.592,0.249,1.204,0.507,1.754,0.885c0.463,0.383,0.954,0.838,1.5,1.391c0.416,0.421,0.956,0.983,1.448,1.595
                                c0.237,0.318,0.432,0.621,0.597,0.926c0.19,0.349,0.358,0.71,0.518,1.078c0.197,0.485,0.389,0.974,0.559,1.467
                                c0.093,0.267,0.228,0.445,0.427,0.561c0.14,0.081,0.3,0.124,0.465,0.124c0.083,0,0.165-0.011,0.244-0.033
                                C26.609,14.292,26.823,14.117,26.946,13.889z"/>
                            </svg>
                            </h2>
                            <ul className='profile_content_section_list'>
                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(0);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <svg width="1em" height="1em" viewBox="0 0 18 18">
                                                <path fill="currentColor" d="M9 0a9 9 0 0 0-9 9 8.654 8.654 0 0 0 .05.92 9 9 0 0 0 17.9 0A8.654 8.654 0 0 0 18 9a9 9 0 0 0-9-9zm5.42 13.42c-.01 0-.06.08-.07.08a6.975 6.975 0 0 1-10.7 0c-.01 0-.06-.08-.07-.08a.512.512 0 0 1-.09-.27.522.522 0 0 1 .34-.48c.74-.25 1.45-.49 1.65-.54a.16.16 0 0 1 .03-.13.49.49 0 0 1 .43-.36l1.27-.1a2.077 2.077 0 0 0-.19-.79v-.01a2.814 2.814 0 0 0-.45-.78 3.83 3.83 0 0 1-.79-2.38A3.38 3.38 0 0 1 8.88 4h.24a3.38 3.38 0 0 1 3.1 3.58 3.83 3.83 0 0 1-.79 2.38 2.814 2.814 0 0 0-.45.78v.01a2.077 2.077 0 0 0-.19.79l1.27.1a.49.49 0 0 1 .43.36.16.16 0 0 1 .03.13c.2.05.91.29 1.65.54a.49.49 0 0 1 .25.75z"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <span>Mon profil</span>
                                            <label>
                                                Ajoutez et modifiez vos informations personnelles
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>

                                <li>
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <span>Email: </span>
                                        </div>
                                        <div>
                                            <span>{user_!==null && user_.email}</span>
                                            {/* <label>
                                                Ajoutez et modifiez vos informations personnelles
                                            </label> */}
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <span>Pays: </span>
                                        </div>
                                        <div>
                                            <span>{(user_!==null && user_.country && user_.country !== null && user_.country !== "null") ? JSON.parse(user_.country)["label"] : ""}</span>
                                            {/* <label>
                                                Ajoutez et modifiez vos informations personnelles
                                            </label> */}
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <span>Téléhpone: </span>
                                        </div>
                                        <div>
                                            <span>{user_!==null && user_.cellphone}</span>
                                            {/* <label>
                                                Ajoutez et modifiez vos informations personnelles
                                            </label> */}
                                        </div>
                                    </div>
                                </li>

                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(5);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <svg width="1em" height="1em" viewBox="0 0 18 18">
                                                <path fill="currentColor" d="M9 0a9 9 0 0 0-9 9 8.654 8.654 0 0 0 .05.92 9 9 0 0 0 17.9 0A8.654 8.654 0 0 0 18 9a9 9 0 0 0-9-9zm5.42 13.42c-.01 0-.06.08-.07.08a6.975 6.975 0 0 1-10.7 0c-.01 0-.06-.08-.07-.08a.512.512 0 0 1-.09-.27.522.522 0 0 1 .34-.48c.74-.25 1.45-.49 1.65-.54a.16.16 0 0 1 .03-.13.49.49 0 0 1 .43-.36l1.27-.1a2.077 2.077 0 0 0-.19-.79v-.01a2.814 2.814 0 0 0-.45-.78 3.83 3.83 0 0 1-.79-2.38A3.38 3.38 0 0 1 8.88 4h.24a3.38 3.38 0 0 1 3.1 3.58 3.83 3.83 0 0 1-.79 2.38 2.814 2.814 0 0 0-.45.78v.01a2.077 2.077 0 0 0-.19.79l1.27.1a.49.49 0 0 1 .43.36.16.16 0 0 1 .03.13c.2.05.91.29 1.65.54a.49.49 0 0 1 .25.75z"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <span>
                                                {
                                                    user !== null ?
                                                        (user.key && user.key.roles) ?
                                                            (user.key.roles.includes('Expert') ? "Expert infos" : "")
                                                        :
                                                            "Devenir un expert"
                                                    :
                                                        ""
                                                }
                                            </span>
                                            <label>
                                                {
                                                    
                                                    user !== null ?
                                                        (user.key && user.key.roles) ?
                                                            (user.key.roles.includes('Expert') ? "Expert infos" : "")
                                                        :
                                                            "Ajoutez et modifiez vos informations"
                                                    :
                                                        "Vos informations ont déja été validé."
                                                }
                                                
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>

                                {
                                    user !== null ?
                                        (( user.key.roles === null ) || (user.key.roles && user.key.roles.includes('Expert') )) ?
                                            <>
                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            <span>Nom: </span>
                                                        </div>
                                                        <div>
                                                            <span>{EXPERT!==null && EXPERT.person.firstName}</span>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            <span>Prénom: </span>
                                                        </div>
                                                        <div>
                                                            <span><span>{EXPERT!==null && EXPERT.person.lastName}</span></span>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            <span>Poste: </span>
                                                        </div>
                                                        <div>
                                                            <span>{EXPERT!==null && EXPERT.workStation} à {EXPERT!==null && EXPERT.workPlace}</span>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            <span>Description: </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            {
                                                                EXPERT !== null ?
                                                                    <p style={{paddingLeft: "30px"}} dangerouslySetInnerHTML={{__html: EXPERT.shortDesc}}></p>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            {
                                                                EXPERT !== null ?
                                                                    <p style={{textAlign:"left", paddingLeft: "30px"}} dangerouslySetInnerHTML={{__html: EXPERT.longDesc}}></p>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div>
                                                            <span>Liens: </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='profile_content_section_list_left'>
                                                        <div style={{paddingLeft: "30px"}}>
                                                            <div>
                                                                <div className='social' style={{display: "flex"}}>
                                                                    <span className='fa fa-facebook' style={{paddingRight: "5px"}}></span>
                                                                    {EXPERT !== null ? EXPERT.facebookLink : ""}
                                                                </div>
                                                                <br/>
                                                                <div className='social' style={{display: "flex"}}>
                                                                    <span className='fa fa-twitter' style={{paddingRight: "5px"}}></span>
                                                                    {EXPERT !== null ? EXPERT.twitterLink : ""}
                                                                </div>
                                                                <br/>
                                                                <div className='social' style={{display: "flex"}}>
                                                                    <span className='fa fa-youtube' style={{paddingRight: "5px"}}></span>
                                                                    {EXPERT !== null ? EXPERT.youtubeLink : ""}
                                                                </div>
                                                                <br/>
                                                                <div className='social' style={{display: "flex"}}>
                                                                    <span className='fa fa-whatsapp' style={{paddingRight: "5px"}}></span>
                                                                    {EXPERT !== null ? EXPERT.whatsappLink : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        :
                                        null
                                    :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                    

                    {/* <div className="profile_content_section">
                        <div className="profile_content_section_">
                            <h2>Connexion
                                <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.617 3.844a2.87 2.87 0 0 0-.451-.868l1.354-1.36L13.904 1l-1.36 1.354a2.877 2.877 0 0 0-.868-.452 3.073 3.073 0 0 0-2.14.075 3.03 3.03 0 0 0-.991.664L7 4.192l4.327 4.328 1.552-1.545c.287-.287.508-.618.663-.992a3.074 3.074 0 0 0 .075-2.14zm-.889 1.804a2.15 2.15 0 0 1-.471.705l-.93.93-3.09-3.09.93-.93a2.15 2.15 0 0 1 .704-.472 2.134 2.134 0 0 1 1.689.007c.264.114.494.271.69.472.2.195.358.426.472.69a2.134 2.134 0 0 1 .007 1.688zm-4.824 4.994l1.484-1.545-.616-.622-1.49 1.551-1.86-1.859 1.491-1.552L6.291 6 4.808 7.545l-.616-.615-1.551 1.545a3 3 0 0 0-.663.998 3.023 3.023 0 0 0-.233 1.169c0 .332.05.656.15.97.105.31.258.597.459.862L1 13.834l.615.615 1.36-1.353c.265.2.552.353.862.458.314.1.638.15.97.15.406 0 .796-.077 1.17-.232.378-.155.71-.376.998-.663l1.545-1.552-.616-.615zm-2.262 2.023a2.16 2.16 0 0 1-.834.164c-.301 0-.586-.057-.855-.17a2.278 2.278 0 0 1-.697-.466 2.28 2.28 0 0 1-.465-.697 2.167 2.167 0 0 1-.17-.854 2.16 2.16 0 0 1 .642-1.545l.93-.93 3.09 3.09-.93.93a2.22 2.22 0 0 1-.711.478z"/></svg>
                            </h2>
                            <ul className='profile_content_section_list'>
                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(4);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24"><path d="M12,1a11,11,0,0,0,0,22,1,1,0,0,0,0-2,9,9,0,1,1,9-9v2.857a1.857,1.857,0,0,1-3.714,0V7.714a1,1,0,1,0-2,0v.179A5.234,5.234,0,0,0,12,6.714a5.286,5.286,0,1,0,3.465,9.245A3.847,3.847,0,0,0,23,14.857V12A11.013,11.013,0,0,0,12,1Zm0,14.286A3.286,3.286,0,1,1,15.286,12,3.29,3.29,0,0,1,12,15.286Z"/></svg>
                                        </div>
                                        <div>
                                            <span>Nom d'utilisateur</span>
                                            <label>
                                                {(user !== null && user.username) ? user.username : "Non défini"}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>


                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(1);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                                <path d="M15.5562 14.5477L15.1007 15.0272C15.1007 15.0272 14.0181 16.167 11.0631 13.0559C8.10812 9.94484 9.1907 8.80507 9.1907 8.80507L9.47752 8.50311C10.1841 7.75924 10.2507 6.56497 9.63424 5.6931L8.37326 3.90961C7.61028 2.8305 6.13596 2.68795 5.26145 3.60864L3.69185 5.26114C3.25823 5.71766 2.96765 6.30945 3.00289 6.96594C3.09304 8.64546 3.81071 12.259 7.81536 16.4752C12.0621 20.9462 16.0468 21.1239 17.6763 20.9631C18.1917 20.9122 18.6399 20.6343 19.0011 20.254L20.4217 18.7584C21.3806 17.7489 21.1102 16.0182 19.8833 15.312L17.9728 14.2123C17.1672 13.7486 16.1858 13.8848 15.5562 14.5477Z" fill="currentColor"/>
                                                <path d="M13.2595 1.87983C13.3257 1.47094 13.7122 1.19357 14.1211 1.25976C14.1464 1.26461 14.2279 1.27983 14.2705 1.28933C14.3559 1.30834 14.4749 1.33759 14.6233 1.38082C14.9201 1.46726 15.3347 1.60967 15.8323 1.8378C16.8286 2.29456 18.1544 3.09356 19.5302 4.46936C20.906 5.84516 21.705 7.17097 22.1617 8.16725C22.3899 8.66487 22.5323 9.07947 22.6187 9.37625C22.6619 9.52466 22.6912 9.64369 22.7102 9.72901C22.7197 9.77168 22.7267 9.80594 22.7315 9.83125L22.7373 9.86245C22.8034 10.2713 22.5286 10.6739 22.1197 10.7401C21.712 10.8061 21.3279 10.53 21.2601 10.1231C21.258 10.1121 21.2522 10.0828 21.2461 10.0551C21.2337 9.9997 21.2124 9.91188 21.1786 9.79572C21.1109 9.56339 20.9934 9.21806 20.7982 8.79238C20.4084 7.94207 19.7074 6.76789 18.4695 5.53002C17.2317 4.29216 16.0575 3.59117 15.2072 3.20134C14.7815 3.00618 14.4362 2.88865 14.2038 2.82097C14.0877 2.78714 13.9417 2.75363 13.8863 2.7413C13.4793 2.67347 13.1935 2.28755 13.2595 1.87983Z" fill="currentColor"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4857 5.3293C13.5995 4.93102 14.0146 4.7004 14.4129 4.81419L14.2069 5.53534C14.4129 4.81419 14.4129 4.81419 14.4129 4.81419L14.4144 4.81461L14.4159 4.81505L14.4192 4.81602L14.427 4.81834L14.4468 4.8245C14.4618 4.82932 14.4807 4.8356 14.5031 4.84357C14.548 4.85951 14.6074 4.88217 14.6802 4.91337C14.8259 4.97581 15.0249 5.07223 15.2695 5.21694C15.7589 5.50662 16.4271 5.9878 17.2121 6.77277C17.9971 7.55775 18.4782 8.22593 18.7679 8.7154C18.9126 8.95991 19.009 9.15897 19.0715 9.30466C19.1027 9.37746 19.1254 9.43682 19.1413 9.48173C19.1493 9.50418 19.1555 9.52301 19.1604 9.53809L19.1665 9.55788L19.1688 9.56563L19.1698 9.56896L19.1702 9.5705C19.1702 9.5705 19.1707 9.57194 18.4495 9.77798L19.1707 9.57194C19.2845 9.97021 19.0538 10.3853 18.6556 10.4991C18.2607 10.6119 17.8492 10.3862 17.7313 9.99413L17.7276 9.98335C17.7223 9.96832 17.7113 9.93874 17.6928 9.89554C17.6558 9.8092 17.5887 9.66797 17.4771 9.47938C17.2541 9.10264 16.8514 8.53339 16.1514 7.83343C15.4515 7.13348 14.8822 6.73078 14.5055 6.50781C14.3169 6.39619 14.1757 6.32909 14.0893 6.29209C14.0461 6.27358 14.0165 6.26254 14.0015 6.25721L13.9907 6.25352C13.5987 6.13564 13.3729 5.72419 13.4857 5.3293Z" fill="currentColor"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <span>Téléphone</span>
                                            <label>
                                                {user !== null && user.numero_telephone ? user.numero_telephone : "Non défini"}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>

                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(2);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                        <svg width="1em" height="1em" viewBox="0 -3.5 32 32" version="1.1">
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Icon-Set-Filled" transform="translate(-414.000000, -261.000000)" fill="currentColor">
                                                    <path d="M430,275.916 L426.684,273.167 L415.115,285.01 L444.591,285.01 L433.235,273.147 L430,275.916 L430,275.916 Z M434.89,271.89 L445.892,283.329 C445.955,283.107 446,282.877 446,282.634 L446,262.862 L434.89,271.89 L434.89,271.89 Z M414,262.816 L414,282.634 C414,282.877 414.045,283.107 414.108,283.329 L425.147,271.927 L414,262.816 L414,262.816 Z M445,261 L415,261 L430,273.019 L445,261 L445,261 Z" id="mail"></path>
                                                </g>
                                            </g>
                                        </svg>
                                        </div>
                                        <div>
                                            <span>E-mail</span>
                                            <label>
                                                {user !== null && user.email ? user.email : "Non défini"}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>

                                <li role='button'
                                    onClick={(e)=>{
                                        setUpdateType(3);
                                    }}
                                >
                                    <div className='profile_content_section_list_left'>
                                        <div>
                                            <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 512 512" >
                                                <g id="Password">
                                                    <path d="M391,233.9478H121a45.1323,45.1323,0,0,0-45,45v162a45.1323,45.1323,0,0,0,45,45H391a45.1323,45.1323,0,0,0,45-45v-162A45.1323,45.1323,0,0,0,391,233.9478ZM184.123,369.3794a9.8954,9.8954,0,1,1-9.8964,17.1387l-16.33-9.4287v18.8593a9.8965,9.8965,0,0,1-19.793,0V377.0894l-16.33,9.4287a9.8954,9.8954,0,0,1-9.8964-17.1387l16.3344-9.4307-16.3344-9.4306a9.8954,9.8954,0,0,1,9.8964-17.1387l16.33,9.4282V323.9487a9.8965,9.8965,0,0,1,19.793,0v18.8589l16.33-9.4282a9.8954,9.8954,0,0,1,9.8964,17.1387l-16.3344,9.4306Zm108,0a9.8954,9.8954,0,1,1-9.8964,17.1387l-16.33-9.4287v18.8593a9.8965,9.8965,0,0,1-19.793,0V377.0894l-16.33,9.4287a9.8954,9.8954,0,0,1-9.8964-17.1387l16.3344-9.4307-16.3344-9.4306a9.8954,9.8954,0,0,1,9.8964-17.1387l16.33,9.4282V323.9487a9.8965,9.8965,0,0,1,19.793,0v18.8589l16.33-9.4282a9.8954,9.8954,0,0,1,9.8964,17.1387l-16.3344,9.4306Zm108,0a9.8954,9.8954,0,1,1-9.8964,17.1387l-16.33-9.4287v18.8593a9.8965,9.8965,0,0,1-19.793,0V377.0894l-16.33,9.4287a9.8954,9.8954,0,0,1-9.8964-17.1387l16.3344-9.4307-16.3344-9.4306a9.8954,9.8954,0,0,1,9.8964-17.1387l16.33,9.4282V323.9487a9.8965,9.8965,0,0,1,19.793,0v18.8589l16.33-9.4282a9.8954,9.8954,0,0,1,9.8964,17.1387l-16.3344,9.4306Z"/>
                                                    <path d="M157.8965,143.9487a98.1035,98.1035,0,1,1,196.207,0V214.147h19.793V143.9487a117.8965,117.8965,0,0,0-235.793,0V214.147h19.793Z"/>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <span>Mot de passe</span>
                                            <label>
                                                *************
                                            </label>
                                        </div>
                                    </div>
                                    <div className='profile_content_section_list_right'>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" ariaHidden="true" focusable="false"><path d="m6.226 3.169 4.29 4.476c.116.141.187.282.187.422s-.047.258-.141.375L6.273 12.92c-.234.234-.586.234-.797.023-.234-.21-.234-.562-.023-.797l3.89-4.078-3.937-4.125a.56.56 0 0 1 .023-.797c.235-.21.586-.21.797.024Z"></path></svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>

            {
                updateType !== null && updateType === 0 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Vos informations seront transmises aux administrateurs.
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' name='personalForm' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    _updateUserInfo_();
                                }}
                            >

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Email</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input defaultValue={user_!==null && user_.email} name='email' placeholder='email@gmail.com' type='email'/>
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Pays</span>
                                        </div>
                                        <div className='login_content_form_input_balise'>
                                            <ReactCountryFlagsSelect
                                                selected={countrySelected}
                                                onSelect={setCountrySelected}
                                                fullWidth
                                                searchable
                                                clearIcon={false}
                                                
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Numéro de téléphone : </span>
                                            {/* <label>{(user.date_naissance && user.date_naissance!==null)?user.date_naissance:null}</label> */}
                                        </div>
                                        <div className='login_content_form_input_balise'>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            {/* <span>Âge: <strong></strong></span> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                updateType !== null && updateType === 5 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        {
                                            user !== null ?
                                                (user.key && user.key.roles && user.key.roles.includes('Expert')) ?
                                                    "Vos informations ont déja été validé."
                                                : "Vos informations seront transmises aux administrateurs."
                                            : ""
                                        }
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' name='expertForm' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if((user.key.roles !== null && !user.key.roles.includes('Expert')) || user.key.roles === null) {
                                        _updateExpertInfos_();
                                    } else {
                                        _makeExpertRequest_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Nom</span><span className='required'>*</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.person.lastName?infoExpert.person.lastName:""} name='lastName' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "person": {
                                                            ...prev.person,
                                                            lastName: e.target.value,
                                                        }
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre nom</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Prénom</span><span className='required'>*</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.person.firstName?infoExpert.person.firstName:""} name='firstName' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "person": {
                                                            ...prev.person,
                                                            firstName: e.target.value,
                                                        }
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre prénom</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Civilité</span><span className='required'>*</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <select defaultValue={"monsieur"}
                                            onChange={(e)=>{
                                                // console.log(e.target.value);
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "person": {
                                                            ...prev.person,
                                                            civility: e.target.value,
                                                        }
                                                    });
                                                })
                                            }}
                                        >
                                            <option value={"monsieur"}>
                                                Monsieur
                                            </option>
                                            <option value={"madame"}>
                                                Madame
                                            </option>
                                            <option value={"mademoiselle"}>
                                                Mademoiselle
                                            </option>
                                        </select>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre nationalité</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Lieu de travail</span><span className='required'>*</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.workPlace?infoExpert.workPlace:""} name='workPlace' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "workPlace": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                        {/* <select
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "workPlace": e.target.value,
                                                    });
                                                })
                                            }}
                                        >
                                            {
                                                options.map((country, index) => (
                                                    <option value={country.label + "/" + country.value}>
                                                        {country.label}
                                                    </option>
                                                ))
                                            }
                                        </select> */}
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Dans quel entreprise travaillez-vous ?</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Quel poste occupez-vous ?</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.workStation?infoExpert.workStation:""} name='workStation' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "workStation": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre Poste</span>
                                    </div>
                                </div>
                                {/* <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Où travaillez-vous ?</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.workPlace?infoExpert.workPlace:""} name='workPlace' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "workPlace": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre lieu de travail</span>
                                    </div>
                                </div> */}
                                {/* <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Quels sont les services que vous proposez</span>
                                        </div>
                                        <br/>
                                        <div className='form_input_balise'>
                                            {generateServices(SERVICE)}
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Un bref aperçu de vous</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={infoExpert.shortDesc?infoExpert.shortDesc:""} onChange={(value) => {
                                            setInfoExpert((prev)=>{
                                                return({
                                                    ...prev,
                                                    "shortDesc": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Parlez-nous de vous en bref</span>
                                    </div>
                                </div>
                                
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>A propos de vous</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={infoExpert.longDesc?infoExpert.longDesc:""} onChange={(value) => {
                                            setInfoExpert((prev)=>{
                                                return({
                                                    ...prev,
                                                    "longDesc": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Parlez-nous de vous en details</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>CV</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input name='file' type='file'/>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Votre CV</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Site web</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={infoExpert.webSite?infoExpert.webSite:""} name='webSite' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "webSite": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Lien vers votre site web</span>
                                    </div>
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Reseaux sociaux</span>
                                    </div>
                                    <div className='form_input_balise flex'>
                                        <span className='fa fa-facebook' style={{paddingRight: "5px"}}></span>
                                        <input value={infoExpert.facebookLink?infoExpert.facebookLink:""} name='facebookLink' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "facebookLink": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_balise flex'>
                                        <span className='fa fa-twitter' style={{paddingRight: "5px"}}></span>
                                        <input value={infoExpert.twitterLink?infoExpert.twitterLink:""} name='twitterLink' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "twitterLink": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_balise flex'>
                                        <span className='fa fa-youtube' style={{paddingRight: "5px"}}></span>
                                        <input value={infoExpert.youtubeLink?infoExpert.youtubeLink:""} name='youtubeLink' placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setInfoExpert((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "youtubeLink": e.target.value,
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Lien vers votre site web</span>
                                    </div>
                                </div>
                                {
                                    ((user !== null && user.key.roles !== null && !user.key.roles.includes('Expert'))) || user.key.roles === null ? 
                                        <div className='form_button'>
                                            <button>
                                                Valider
                                            </button>
                                        </div>
                                    :
                                        null
                                }
                                
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }


            {
                updateType !== null && updateType === 1 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Afin de renforcer la sécurité de votre compte, nous devons vérifier votre numéro de téléphone.
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nouveau téléphone</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={user.numero_telephone} name='telephone' type='tel'
                                                onChange={(e)=>{
                                                    setUser((prevUser) => ({
                                                        ...prevUser,
                                                        numero_telephone: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <br/>
                                        <div className='form_input_title'>
                                            <span>Mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required value={password} name='password-tel' type='password'
                                                onChange={(e)=>{
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                updateType !== null && updateType === 2 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Afin de renforcer la sécurité de votre compte, nous devons vérifier votre e-mail.
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <div className=''>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nouvel e-mail</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={user.email} name='email' type='email'
                                                onChange={(e)=>{
                                                    setUser((prevUser) => ({
                                                        ...prevUser,
                                                        email: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required value={password} name='password-mail' type='password'
                                                onChange={(e)=>{
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                updateType !== null && updateType === 3 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Le mot de passe doit être suffisamment fort pour assurer un niveau de sécurité élevé. Il doit être changé régulièrement, ou au plus tard en cas de compromission.
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form' name='passwordForm'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Ancien mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required name='old_password' type='password'
                                                onChange={(e)=>{
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nouveau mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required name='password' placeholder='***********' type='password'
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    let password_verification_results_ = {
                                                        number: false,
                                                        string: false,
                                                        special_character: false,
                                                        minimum_character: false,
                                                        isOk: false,
                                                        isConfirmed: false,
                                                    };
                                                    let result = VERF._password(value, 8);

                                                    password_verification_results_.minimum_character = result.minimum_character;
                                                    password_verification_results_.special_character = result.special_character;
                                                    password_verification_results_.string = result.string;
                                                    password_verification_results_.number = result.number;

                                                    
                                                    if( document.forms["passwordForm"]["confirmPassword"].value.length > 0 &&  document.forms["passwordForm"]["confirmPassword"].value === value ) {
                                                        password_verification_results_.isConfirmed = true;
                                                    }else {
                                                        password_verification_results_.isConfirmed = false;
                                                    }

                                                    setpassword_verification_results(password_verification_results_);
                                                }}
                                            />
                                        </div>
                                        <div className='login_content_form_input_verf'>
                                            <span
                                                style={{
                                                    color: !password_verification_results.minimum_character ? "#d11919" : "#5dc269",
                                                }}
                                            >- Votre mot de passe doit contenir au minimum 8 caractères.</span>
                                            
                                            <span
                                                style={{
                                                    color: !password_verification_results.number ? "#d11919" : "#5dc269",
                                                }}
                                            >- Votre mot de passe doit contenir au moins un chiffre.</span>
                                            
                                            <span
                                                style={{
                                                    color: !password_verification_results.string ? "#d11919" : "#5dc269",
                                                }}
                                            >- Votre mot de passe doit contenir au moins une lettre.</span>
                                            
                                            <span
                                                style={{
                                                    color: !password_verification_results.special_character ? "#d11919" : "#5dc269",
                                                }}
                                            >- Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « # », « % », « & », « $ » et « _ ».</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex'>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Confirmez votre nouveau mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input name='confirmPassword' required placeholder='***********' type='password'
                                                onChange={(e) => {
                                                    let value = e.target.value;

                                                    if( document.forms["passwordForm"]["password"].value.length > 0 &&  document.forms["passwordForm"]["password"].value === value ) {
                                                        setpassword_verification_results({
                                                            ...password_verification_results,
                                                            isConfirmed: true,
                                                        })
                                                    }else {
                                                        setpassword_verification_results({
                                                            ...password_verification_results,
                                                            isConfirmed: false,
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='login_content_form_input_verf'>
                                            <span
                                                style={{
                                                    color: !password_verification_results.isConfirmed ? "#d11919" : "#5dc269",
                                                }}
                                            >confirmer</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }

            {
                updateType !== null && updateType === 4 ?
                    <section className='information'>
                        <div className='information_content white'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setUpdateType(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='information_content_banner'>
                                <div className='information_content_banner_content'>
                                    <svg fill='currentColor' height="1em" width="1em" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                        <g>
                                            <path class="st0" d="M290.671,135.434c37.324-3.263,64.949-36.175,61.663-73.498c-3.241-37.324-36.152-64.938-73.476-61.675
                                                c-37.324,3.264-64.949,36.164-61.686,73.488C220.437,111.096,253.348,138.698,290.671,135.434z"/>
                                            <path class="st0" d="M311.31,406.354c-16.134,5.906-43.322,22.546-43.322,22.546s20.615-95.297,21.466-99.446
                                                c8.71-41.829,33.463-100.86-0.069-136.747c-23.35-24.936-53.366-18.225-79.819,7.079c-17.467,16.696-26.729,27.372-42.908,45.322
                                                c-6.55,7.273-9.032,14.065-5.93,24.717c3.332,11.515,16.8,17.226,28.705,12.871c16.134-5.895,43.3-22.534,43.3-22.534
                                                s-12.595,57.997-18.869,87c-0.874,4.137-36.06,113.292-2.505,149.18c23.35,24.949,53.343,18.226,79.819-7.066
                                                c17.467-16.698,26.729-27.373,42.908-45.334c6.55-7.263,9.009-14.054,5.93-24.706C336.66,407.733,323.215,402.01,311.31,406.354z"
                                                />
                                        </g>
                                    </svg>
                                    <span>
                                        Modifier le nom d'utilisateur
                                    </span>
                                </div>
                            </div>
                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                }}
                            >
                                <div className=''>
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Nouveau nom d'utilisateur</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={userName} name='username' type='text'
                                                onChange={(e)=>{
                                                    setUserName(e.target.value,);
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Mot de passe</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input required value={password} name='password-mail' type='password'
                                                onChange={(e)=>{
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </section>
                :
                    null
            }
        </div>
    );
}

export default DashboardProfile;