import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();
const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

class ServiceRequest {
    add(data, image, category = []) {
        let cat = "";
        category.map((ca) => {
            cat += "&category=" + ca;
            return 0;
        });
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "services" + (data.parent ? ("?parent=" + data.parent + "&") : "?") + "featuredImage=" + image + cat,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    validate(id) {
        return (
            axios({
                method: 'put',
                url: PATH__.api_root + "services/validate/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    linkMedia(data, articleId, mediaId) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "services/" + articleId + "/medias?media=" + mediaId,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    unlinkMedia(articleId, mediaIndex) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "services/" + articleId + "/medias/" + mediaIndex,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    GetAll(limit = 20, page = 1, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "services/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    GetAll_(limit = 20, page = 1, data) {
        // console.log(data);
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/list/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + (currentUser ? currentUser.key.authKey : ""),
                },
                data: data,
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "services/" + id,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data, image, category = []) {
        let cat = "";
        category.map((ca) => {
            cat += "category=" + ca + "&";
            return 0;
        });
        return (
            axios({
                method: 'patch',
                data: data,
                url: image !== null ? PATH__.api_root + "services/" + id + "?image=" + image + "&" + cat : PATH__.api_root + "services/" + id + "?" + cat,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    deleteCategories(id, category) {
        let cat = "";
        category.map((ca, index) => {
            cat += "category=" + ca + (index < category.length - 1 ? "&" : "");
            return 0;
        });
        // console.log(PATH__.api_root + "services/" + id + "/categories/?" + cat);
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "services/" + id + "/categories?" + cat,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getByCategories(limit = 20, page = 1, data) {
        // console.log(data);
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/list/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
                data: data,
            })
        )
    }

    getByNotCategories(limit = 20, page = 1, category) {
        let cat = "";
        category.map((ca, index) => {
            cat += "category=" + ca + (index < category.length - 1 ? "&" : "");
            return 0;
        });
        // console.log(PATH__.api_root + "services/by/categories/?" + cat + "&limit=" + limit + "&page=" + page);
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "services/list/by/categories/not?" + cat + "&limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "services/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    submitRate(serviceId, data) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/" + serviceId + "/rates",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    updateRate(serviceId, data) {
        return (
            axios({
                method: 'patch',
                url: PATH__.api_root + "services/" + serviceId + "/rates",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    submitFree(serviceId) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/" + serviceId + "/done",
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getExpertRate(serviceId, limit = 20, page = 1) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/" + serviceId + "/rates/list?limit=" + limit + "&page=" + page,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    getExpertFree(serviceId, limit = 20, page = 1) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "services/" + serviceId + "/experts?limit=" + limit + "&page=" + page,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }
}

export default ServiceRequest;